import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { CircularProgress } from "@material-ui/core";
import WarningIcon from "@material-ui/icons/Warning";
import Avatar from "@material-ui/core/Avatar";

const useStyles = makeStyles((theme) => ({
  warning: {
    color: "#fff",
    backgroundColor: theme.palette.warning,
  },
}));

function AsyncContent({ loading, error, children }) {
  const classes = useStyles();

  if (loading) {
    return <CircularProgress />;
  }

  if (error) {
    <Avatar variant="rounded" className={classes.warning}>
      <WarningIcon />
    </Avatar>;
  }

  return children;
}

AsyncContent.propTypes = {
  loading: PropTypes.bool,
  error: PropTypes.object,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

export default AsyncContent;
