import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { OrganizationSelector } from "../../widgets";
import Switch from "@material-ui/core/Switch";
import Typography from "@material-ui/core/Typography";
import SubscriptionSeatsWidget from "./SubscriptionSeatsWidget";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-end",
    margin: theme.spacing(2),
  },
  orgSelector: {
    width: 200,
  },
  switchContainer: {
    display: "flex",
    alignItems: "center",
    marginLeft: "auto",
  },
}));

function UserListControl(props) {
  const classes = useStyles();

  const {
    showDisabledUsers,
    enableOrgSelector,
    selectedOrganization,
    onShowDisabledUsersChange,
    onOrganizationChange,
  } = props;

  return (
    <div className={classes.root}>
      {enableOrgSelector && (
        <OrganizationSelector
          className={classes.orgSelect}
          organization={selectedOrganization}
          onChange={onOrganizationChange}
          size="small"
          variant="outlined"
        />
      )}
      <SubscriptionSeatsWidget organizationId={selectedOrganization.id} />
      <div className={classes.switchContainer}>
        <Switch
          checked={showDisabledUsers}
          onChange={onShowDisabledUsersChange}
        />
        <Typography>Show deactivated users</Typography>
      </div>
    </div>
  );
}

UserListControl.propTypes = {
  showDisabledUsers: PropTypes.bool,
  enableOrgSelector: PropTypes.bool,
  selectedOrganization: PropTypes.object,
  onShowDisabledUsersChange: PropTypes.func,
  onOrganizationChange: PropTypes.func,
};

export default UserListControl;
