import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { DataGrid } from "@material-ui/data-grid";
import ReportDownloadButton from "./ReportDownloadButton";
import firebase from "firebase/app";
import "firebase/firestore";
import { useDocumentDataOnce } from "react-firebase-hooks/firestore";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
import { Column } from "../../widgets";

const useStyles = makeStyles((theme) => ({
  buttonRow: {
    flex: "0 1 auto",
    display: "flex",
    flexDirection: "row",
  },
  grid: {
    flex: "1 1 auto",
    margin: theme.spacing(2),
  },
  create: {
    flex: "0 1 auto",
    marginBottom: theme.spacing(1),
  },
  spacer: {
    flex: "1 1 auto",
  },
  orgSelect: {
    flex: "0 1 auto",
    marginBottom: theme.spacing(1),
    minWidth: 200,
  },
}));

const dateFormat = new Intl.DateTimeFormat("default", {
  dateStyle: "short",
  timeStyle: "short",
});

const makeColumns = (downloadButtons) => [
  {
    field: "date",
    headerName: "Date",
    width: 200,
  },
  {
    field: "inspector",
    headerName: "Inspector",
    width: 200,
  },
  {
    field: "location",
    headerName: "Location",
    width: 150,
  },
  {
    field: "city",
    headerName: "City",
    width: 150,
  },
  {
    field: "state",
    headerName: "State",
    width: 125,
  },
  {
    field: "samples",
    headerName: "Samples",
    width: 150,
  },
  {
    field: "report",
    headerName: "Report",
    renderCell: downloadButtons,
    disableColumnMenu: true,
    sortable: false,
    width: 150,
  },
];

function mapSnapshotToRows(snapshot) {
  if (!snapshot) return [];

  const rows = snapshot.docs.map((doc) => {
    const data = doc.data();
    return {
      id: doc.id,
      date: dateFormat.format(data.createdAt?.toDate()),
      inspector: data.inspector,
      location: data.location,
      city: data.city,
      state: data.state,
      samples: data.sampleCount ?? 0,
      report: doc.id,
    };
  });

  return rows;
}

function InspectionListExpanded(props) {
  const {
    organizationId,
    loading,
    snapshot,
    error,
    onItemSelected,
    onShowSnackbar,
  } = props;
  const classes = useStyles();

  const [organization, orgLoading, orgErrorIgnored] = useDocumentDataOnce(
    firebase.firestore().doc(`organizations/${organizationId}`)
  );

  useEffect(() => {
    document.title = "Inspections - Runyon Data Services";
  });

  const handleSelectionModelChange = (selectionModel) => {
    onItemSelected(selectionModel.length > 0 ? selectionModel[0] : undefined);
  };

  const handleReportReady = (downloadUrl) => {
    window.location = downloadUrl;
  };

  const handleError = (error) => {
    onShowSnackbar({
      open: true,
      message: "An unexpected error occurred while building your report.",
      severity: "error",
      error: error,
    });
  };

  const handleCellClick = (params, event) => {
    if (params.colDef.field == "report") {
      event.stopPropagation();
    }
  };

  const reportTypes = organization?.reportSettings?.supportedFormats;

  const downloadButtons = (params) => {
    return (
      <React.Fragment>
        {orgLoading && <CircularProgress />}
        {reportTypes && (
          <ReportDownloadButton
            inspectionId={params.value}
            onReportReady={handleReportReady}
            onError={handleError}
            reportTypes={reportTypes}
          />
        )}
      </React.Fragment>
    );
  };

  const columns = makeColumns(downloadButtons);
  const rows = mapSnapshotToRows(snapshot);

  return (
    <React.Fragment>
      {rows.length == 0 && !loading && (
        <Column spacing={2} horizontalAlignment="centerHorizontally">
          <img src="images/NoDocuments@2x.png" />
          <Typography>There are no inspections to display</Typography>
        </Column>
      )}
      {(loading || rows.length > 0) && (
        <DataGrid
          className={classes.grid}
          rows={rows}
          columns={columns}
          loading={loading}
          error={error}
          onSelectionModelChange={handleSelectionModelChange}
          onCellClick={handleCellClick}
        />
      )}
    </React.Fragment>
  );
}

InspectionListExpanded.propTypes = {
  organizationId: PropTypes.string,
  loading: PropTypes.bool,
  snapshot: PropTypes.object,
  error: PropTypes.object,
  onItemSelected: PropTypes.func,
  onShowSnackbar: PropTypes.func,
};

export default InspectionListExpanded;
