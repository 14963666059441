import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import CssBaseline from "@material-ui/core/CssBaseline";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import { useLocation } from "react-router-dom";
import { CircularProgress, Typography } from "@material-ui/core";
import qs from "qs";
import AcceptInviteForm from "./AcceptInviteForm";
import { acceptInvitation, getInvitedUser } from "../../data/user-store";
import { ErrorState } from "../../widgets";
import { AppBar } from "@material-ui/core";
import { Toolbar } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Backdrop from "@material-ui/core/Backdrop";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    background: theme.palette.gray.veryVeryLight,
    minHeight: "100vh",
    overflowY: "scroll",
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  formContent: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "50%",
    minWidth: 300,
    maxWidth: 600,
    marginTop: "auto",
    marginBottom: "auto",
    padding: 16,
  },
  otherContent: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: 350,
    marginTop: "auto",
    marginBottom: "auto",
    padding: 16,
  },
  appBarLogo: {
    height: 50,
    margin: 8,
  },
  signInButton: {
    width: "100%",
  },
  successText: {
    marginBottom: 16,
  },
  orText: {
    marginTop: 16,
    marginBottom: 16,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  appleLogo: {
    width: "100%",
    height: 83,
  },
  googleLogo: {
    height: 110,
  },
}));

function renderContent(token, classes) {
  const history = useHistory();

  const [saving, setSaving] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [invitedUser, setInvitedUser] = useState(null);

  useEffect(async () => {
    if (success) return;

    setLoading(true);

    try {
      const user = await getInvitedUser(token);
      setInvitedUser(user);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  }, [token]);

  const handleSubmit = async (formState) => {
    setSaving(true);

    try {
      await acceptInvitation(token, formState);

      setSuccess(true);
    } catch (error) {
      setError(error);
    } finally {
      setSaving(false);
    }
  };

  const handleSignInClick = () => {
    history.push("/sign-in");
  };

  if (loading) {
    return (
      <Paper className={classes.otherContent} elevation={5}>
        <CircularProgress />
        <Typography>Please wait...</Typography>
      </Paper>
    );
  }

  if (error) {
    return (
      <Paper className={classes.formContent} elevation={5}>
        <ErrorState message={error.message} />
      </Paper>
    );
  }

  if (success) {
    return (
      <Paper className={classes.otherContent} elevation={5}>
        <Typography className={classes.successText} variant="h6">
          Your account was successfuly created!
        </Typography>
        <Button
          className={classes.signInButton}
          variant="contained"
          color="primary"
          onClick={handleSignInClick}
        >
          Sign In
        </Button>
        <Typography className={classes.orText}>or</Typography>
        <a href="https://apps.apple.com/us/app/runyon-data-services/id1591445265?itsct=apps_box_badge&amp;itscg=30200">
          <img
            src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/en-us?size=250x83&amp;releaseDate=1635206400&amp;h=f0292afa07f953430eac873f5672821c"
            alt="Download on the App Store"
            className={classes.appleLogo}
          />
        </a>
        <a href="https://play.google.com/store/apps/details?id=com.runyondataservices.mobile&amp;hl=en_US&amp;gl=US&amp;pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1">
          <img
            alt="Get it on Google Play"
            src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png"
            className={classes.googleLogo}
          />
        </a>
      </Paper>
    );
  }

  return (
    <Paper className={classes.formContent} elevation={5}>
      <Backdrop className={classes.backdrop} open={saving}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <AcceptInviteForm user={invitedUser} onSubmit={handleSubmit} />
    </Paper>
  );
}

export default function AcceptInvite() {
  const classes = useStyles();
  const location = useLocation();
  const params = qs.parse(location.search, { ignoreQueryPrefix: true });
  const token = params.token;

  useEffect(() => {
    document.title = "Accept Invite - Runyon Data Services";
  });

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="absolute" className={classes.appBar}>
        <Toolbar className={classes.toolbar} variant="dense">
          <img src="images/rds-horz-white.png" className={classes.appBarLogo} />
        </Toolbar>
      </AppBar>

      {renderContent(token, classes)}
    </div>
  );
}
