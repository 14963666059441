import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import firebase from "firebase/app";
import "firebase/firestore";
import { useCollection } from "react-firebase-hooks/firestore";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import GridCard from "../../widgets/GridCard.jsx";
import { useAuth } from "../../hooks/use-auth";

const useItemStyles = makeStyles((theme) => ({
  photo: {
    paddingRight: theme.spacing(2),
  },
}));

function createQuery(inspectionId, auth) {
  const signatures = firebase
    .firestore()
    .collection(`inspections/${inspectionId}/signatures`);

  if (auth.user.claims.isAdmin || auth.user.claims.isSuperAdmin) {
    return signatures;
  } else {
    return signatures
      .where("organization", "==", auth.user.claims.organization)
      .where("author", "==", auth.authUser.uid);
  }
}

function getContractorLabel(contractorType) {
  switch (contractorType) {
    case "general":
      return "General Contractor";
    case "polishing":
      return "Polishing Contractor";
    default:
      return "Other Contractor";
  }
}

function SignatureListItem(props) {
  const { doc } = props;
  const classes = useItemStyles();
  const data = doc.data();

  const contractor = getContractorLabel(data.contractorType);

  return (
    <ListItem divider>
      <ListItemIcon className={classes.photo}>
        <img height={100} src={`data:image/png;base64,${data.data}`} />
      </ListItemIcon>
      <ListItemText primary={data.name} secondary={contractor} />
    </ListItem>
  );
}

SignatureListItem.propTypes = {
  doc: PropTypes.object,
};

function SignatureList(props) {
  const { inspectionId } = props;
  const [auth] = useAuth();
  const [snapshot, loading, error] = useCollection(
    createQuery(inspectionId, auth)
  );

  const empty = snapshot && snapshot.docs.length == 0;

  const renderRows = () => {
    return snapshot.docs.map((doc) => {
      return <SignatureListItem key={doc.id} doc={doc} />;
    });
  };

  return (
    <GridCard
      title="Signatures"
      empty={empty}
      loading={loading}
      error={error?.message}
    >
      <List>{snapshot && renderRows()}</List>
    </GridCard>
  );
}

SignatureList.propTypes = {
  inspectionId: PropTypes.string,
};

export default SignatureList;
