import React from "react";
import { useLocation } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { usePaymentIntent } from "../../hooks/stripe-hooks";
import CircularProgress from "@material-ui/core/CircularProgress";
import { green, yellow } from "@material-ui/core/colors";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ErrorIcon from "@material-ui/icons/Error";
import WatchLaterIcon from "@material-ui/icons/WatchLater";
import { Column } from "../../widgets";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles(() => ({
  root: {
    width: "50%",
    minWidth: 300,
    maxWidth: 400,
    marginTop: "auto",
    marginBottom: "auto",
  },
}));

export default function PaymentComplete() {
  const classes = useStyles();

  const location = useLocation();

  const query = new URLSearchParams(location.search);
  const clientSecret = query.get("payment_intent_client_secret");

  const [paymentIntent, loading, error] = usePaymentIntent(clientSecret);
  const history = useHistory();

  const handleSignInClicked = () => {
    history.replace("/");
  };

  if (error) {
    console.log(error);
  }

  return (
    <Card className={classes.root}>
      <CardContent>
        {loading && <CircularProgress />}
        {error && (
          <Column horizontalAlignment="centerHorizontally">
            <ErrorIcon style={{ color: yellow[500] }} />
            <Typography>
              Something went wrong. Please try again or contact support.
            </Typography>
          </Column>
        )}
        {paymentIntent.status == "succeeded" && (
          <Column horizontalAlignment="centerHorizontally">
            <CheckCircleIcon style={{ color: green[500], fontSize: 100 }} />
            <Typography>Success! Payment received.</Typography>
            <br />
            <Button
              fullWidth
              variant="contained"
              color="primary"
              onClick={handleSignInClicked}
            >
              Sign In
            </Button>
          </Column>
        )}
        {paymentIntent.status == "processing" && (
          <Column horizontalAlignment="centerHorizontally">
            <WatchLaterIcon style={{ color: green[500], fontSize: 100 }} />
            <Typography>
              Payment Processing. We will update you when payment is received.
            </Typography>
          </Column>
        )}
        {paymentIntent.status == "requires_payment_method" && (
          <Column horizontalAlignment="centerHorizontally">
            <ErrorIcon style={{ color: yellow[500], fontSize: 100 }} />
            <Typography>
              Payment failed. Please try another payment method.
            </Typography>
          </Column>
        )}
      </CardContent>
    </Card>
  );
}
