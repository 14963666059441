import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { useAuth } from "../../hooks/use-auth.js";
import Copyright from "../../widgets/Copyright.jsx";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    background: "linear-gradient(180deg, white, 10%, white, 60%, #004E89)",
    minHeight: "100vh",
    overflowY: "scroll",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  loginBox: {
    width: "30%",
    minWidth: 300,
    maxWidth: 600,
    marginTop: "auto",
  },
  linksRow: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    width: "30%",
    minWidth: 300,
    maxWidth: 600,
    marginTop: "8px",
    marginBottom: "auto",
  },
  docLink: {
    color: "#FFFFFF",
  },
  logo: {
    marginTop: "auto",
    marginBottom: "auto",
    width: "30%",
    minWidth: 200,
    maxWidth: 400,
  },
}));

export default function SignIn() {
  const classes = useStyles();
  const history = useHistory();
  const [auth] = useAuth();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [resetEmail, setResetEmail] = useState("");
  const [showResetPasswordDialog, setShowResetPasswordDialog] = useState(false);
  const [showResetPasswordConfirmation, setShowResetPasswordConfirmation] =
    useState(false);

  const handleSubmit = function (event) {
    event.preventDefault();
    auth.signIn(email, password);
  };

  const handleForgotPasswordClicked = () => {
    setShowResetPasswordDialog(true);
  };

  const handleSignUpClicked = () => {
    history.push("/sign-up");
  };

  const handleCloseResetPasswordDialog = () => {
    setShowResetPasswordDialog(false);
  };

  const handleSubmitResetPassword = async () => {
    setShowResetPasswordDialog(false);
    await auth.sendPasswordResetEmail(resetEmail);
    setResetEmail("");
    setShowResetPasswordConfirmation(true);
  };

  const handleCloseResetPasswordConfirmation = () => {
    setShowResetPasswordConfirmation(false);
  };

  useEffect(() => {
    document.title = "Sign In - Runyon Data Services";
  });

  return (
    <div className={classes.root}>
      <CssBaseline />
      <img src="images/rds-main.png" className={classes.logo} />
      <Card className={classes.loginBox} elevation={5}>
        <CardContent>
          <Typography gutterBottom variant="h5" component="h2">
            Welcome
          </Typography>

          <form className={classes.form} noValidate onSubmit={handleSubmit}>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
              value={email}
              onChange={(evt) => setEmail(evt.target.value)}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              value={password}
              onChange={(evt) => setPassword(evt.target.value)}
            />
            {auth.error && (
              <Typography component="h3">{auth.error.message}</Typography>
            )}
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
            >
              Sign In
            </Button>
          </form>
        </CardContent>
        <CardActions>
          <Button
            size="small"
            color="primary"
            onClick={handleForgotPasswordClicked}
          >
            Forgot your password?
          </Button>
          <Button size="small" color="primary" onClick={handleSignUpClicked}>
            Sign Up
          </Button>
        </CardActions>
      </Card>
      <Box className={classes.linksRow}>
        <a className={classes.docLink} href="/terms-of-use">
          Terms of Use
        </a>
        <a className={classes.docLink} href="/privacy-policy">
          Privacy Policy
        </a>
      </Box>
      <Box mt={8}>
        <Copyright />
      </Box>
      <Dialog
        open={showResetPasswordDialog}
        onClose={handleCloseResetPasswordDialog}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Forgot your password?</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Enter your email address and we&apos;ll send you instructions on how
            to reset it.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Enter your email"
            type="email"
            fullWidth
            value={resetEmail}
            onChange={(evt) => setResetEmail(evt.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseResetPasswordDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSubmitResetPassword} color="primary">
            Submit
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={showResetPasswordConfirmation}
        onClose={handleCloseResetPasswordConfirmation}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Request Submitted</DialogTitle>
        <DialogActions>
          <Button
            onClick={handleCloseResetPasswordConfirmation}
            color="primary"
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
