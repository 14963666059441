import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/storage";
import { useCollection } from "react-firebase-hooks/firestore";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import GridCard from "../../widgets/GridCard.jsx";
import FirebaseImage from "../../widgets/FirebaseImage.jsx";
import { useAuth } from "../../hooks/use-auth";

const useItemStyles = makeStyles((theme) => ({
  item: {
    padding: theme.spacing(2),
  },
  photo: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: 150,
    height: 100,
    marginRight: theme.spacing(2),
  },
}));

function createQuery(inspectionId, auth) {
  const photos = firebase
    .firestore()
    .collection(`inspections/${inspectionId}/photos`);

  if (auth.user.claims.isAdmin || auth.user.claims.isSuperAdmin) {
    return photos;
  } else {
    return photos
      .where("organization", "==", auth.user.claims.organization)
      .where("author", "==", auth.authUser.uid);
  }
}

function PhotoListItem(props) {
  const { doc } = props;
  const classes = useItemStyles();
  const data = doc.data();

  const storagePath = `${data.organization}/images/${doc.id}.jpg`;

  return (
    <ListItem divider>
      <ListItemIcon className={classes.photo}>
        <FirebaseImage storagePath={storagePath} height={100} width={150} />
      </ListItemIcon>
      <ListItemText primary={data.label} secondary={data.comments} />
    </ListItem>
  );
}

PhotoListItem.propTypes = {
  doc: PropTypes.object,
};

function PhotoList(props) {
  const { inspectionId } = props;
  const [auth] = useAuth();
  const [snapshot, loading, error] = useCollection(
    createQuery(inspectionId, auth)
  );

  const empty = snapshot && snapshot.docs.length == 0;

  const renderRows = () => {
    return snapshot.docs.map((doc) => {
      return <PhotoListItem key={doc.id} doc={doc} />;
    });
  };

  return (
    <GridCard
      title="Photos"
      empty={empty}
      loading={loading}
      error={error?.message}
    >
      <List>{snapshot && renderRows()}</List>
    </GridCard>
  );
}

PhotoList.propTypes = {
  classes: PropTypes.object,
  inspectionId: PropTypes.string,
};

export default PhotoList;
