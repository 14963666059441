import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Typography from "@material-ui/core/Typography";
import { useHistory } from "react-router-dom";
import SubscriptionTierCard from "../../widgets/SubscriptionTierCard";
import resolveConfig from "../../util/resolve-config";

const useStyles = makeStyles((theme) => ({
  plans: {
    display: "flex",
    flexDirection: "row",
    gap: theme.spacing(3),
  },
  form: {
    width: "100%", // Fix IE 11 issue.
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  docLink: {
    color: "#FFFFFF",
  },
  title: {
    fontWeight: "bold",

    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
}));

export default function SubscriptionTiers() {
  const classes = useStyles();
  const history = useHistory();
  const { subscriptionTiers } = resolveConfig();

  const handleTierSelected = (product, quantity) => {
    history.push(
      `/sign-up/create-account?product=${product}&quantity=${quantity}`
    );
  };

  return (
    <React.Fragment>
      <CssBaseline />
      <Typography className={classes.title} variant="h3">
        Simple Pricing
      </Typography>
      <div className={classes.plans}>
        <SubscriptionTierCard
          product={subscriptionTiers.individual.priceId}
          quantity={1}
          onSelected={handleTierSelected}
        />
        <SubscriptionTierCard
          product={subscriptionTiers.team.priceId}
          quantity={1}
          onSelected={handleTierSelected}
        />
        <SubscriptionTierCard
          product={subscriptionTiers.enterprise.priceId}
          quantity={1}
          onSelected={handleTierSelected}
        />
      </div>
    </React.Fragment>
  );
}
