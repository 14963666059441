import React, { useState } from "react";
import PropTypes from "prop-types";
import { resendInvitation } from "../../data/user-store";
import { ProgressButton } from "../../widgets";

function ResendInvitationButton(props) {
  const { email, onShowSnackbar } = props;

  const [sending, setSending] = useState(false);

  const handleClick = async () => {
    setSending(true);

    try {
      await resendInvitation(email);

      onShowSnackbar({
        open: true,
        message: "Invite resent.",
        severity: "success",
        autoHideDuration: 6000,
      });
    } catch (error) {
      onShowSnackbar({
        open: true,
        message: "Resend invite failed.",
        severity: "error",
        error: error,
      });
    } finally {
      setSending(false);
    }
  };

  return (
    <ProgressButton color="primary" onClick={handleClick} loading={sending}>
      Resend
    </ProgressButton>
  );
}

ResendInvitationButton.propTypes = {
  email: PropTypes.string,
  onShowSnackbar: PropTypes.func,
};

export default ResendInvitationButton;
