import React, { useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";

import { useAuth } from "../hooks/use-auth.js";

import userStore from "../data/user-store";
import { getLegalDoc } from "../data/legal-doc-store";

import { getDialogTitle } from "../util/legal-doc-utils.js";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import { Typography } from "@material-ui/core";

const getNextDialogState = async (userId) => {
  const docs = await userStore.getLegalDocsNeedingAcceptance(userId);
  if (!docs) {
    console.log("Problem getting legal docs");
    return { isVisible: false };
  }

  const doc = docs.shift();

  if (!doc) {
    return { isVisible: false };
  }

  console.log("getting markdown");
  const markdown = await getLegalDoc(doc);

  return {
    isVisible: true,
    docType: doc,
    markdown: markdown,
  };
};

export default function LegalDocChecker() {
  const [auth] = useAuth();
  const [dialogState, setDialogState] = useState({ isVisible: false });
  const [showLogoutPrompt, setShowLogoutPrompt] = useState(false);

  const checkForLegalDocToAccept = async () => {
    const nextState = await getNextDialogState(auth.authUser.uid);

    setDialogState(nextState);
  };

  const handleAccept = async () => {
    setDialogState({
      isVisible: false,
      docType: dialogState.docType,
      markdown: dialogState.markdown,
    });

    await userStore.acceptLegalDoc(auth.authUser.id, dialogState.docType);

    checkForLegalDocToAccept();
  };

  const handleDecline = () => {
    setDialogState({
      isVisible: false,
      docType: dialogState.docType,
      markdown: dialogState.markdown,
    });

    setShowLogoutPrompt(true);
  };

  const handleSignOut = () => {
    auth.signOut();
  };

  useEffect(() => {
    checkForLegalDocToAccept();
  }, []);

  return (
    <React.Fragment>
      <Dialog open={dialogState.isVisible} aria-labelledby="legal-dialog-title">
        <DialogTitle id="legal-dialog-title">
          {getDialogTitle(dialogState.docType)}
        </DialogTitle>
        <DialogContent>
          <ReactMarkdown remarkPlugins={[remarkGfm]}>
            {dialogState.markdown}
          </ReactMarkdown>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDecline} color="primary">
            I Decline
          </Button>
          <Button onClick={handleAccept} color="primary">
            I Accept
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={showLogoutPrompt} aria-labelledby="legal-dialog-title">
        <DialogTitle id="legal-dialog-title">Acceptance Required</DialogTitle>
        <DialogContent>
          <Typography>
            You must accept all of the required legal documents in order to use
            the service.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSignOut} color="primary">
            Sign Out
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
