import { getUrl } from "../util/legal-doc-utils";

export async function getLegalDoc(docType) {
  const url = getUrl(docType);

  const response = await fetch(url);

  if (response.ok) {
    return await response.text();
  } else {
    return null;
  }
}
