import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import SettingsIcon from "@material-ui/icons/Settings";
import EditIcon from "@material-ui/icons/Edit";
import PaymentIcon from "@material-ui/icons/Payment";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import ProfileMenuButton from "./ProfileMenuButton.jsx";
import { Divider } from "@material-ui/core";
import EditUserForm from "../pages/users/EditUserForm";
import ControlledSnackbar from "../widgets/ControlledSnackbar.jsx";
import { useHistory } from "react-router-dom";
import { useAuth } from "../hooks/use-auth.js";

const useStyles = makeStyles(() => ({
  root: {
    width: 500, // keep right padding when drawer closed
  },
}));

function ProfileMenu(props) {
  const { anchorEl, onClose } = props;
  const classes = useStyles();
  const history = useHistory();
  const [auth] = useAuth();

  const [editUserFormOpen, setEditUserFormOpen] = useState(false);
  const [snackbarState, setSnackbarState] = useState({});

  const displayName = auth.authUser.displayName;
  const isAdmin = auth.user.claims.isAdmin;
  const userId = auth.authUser.uid;

  const handleEditCancel = () => {
    setEditUserFormOpen(false);
  };

  const handleEditSave = (state) => {
    setEditUserFormOpen(false);
    setSnackbarState(state);
  };

  const handleSnackbarClose = () => {
    setSnackbarState({
      ...snackbarState,
      ["open"]: false,
    });
  };

  const handleEditProfileClick = () => {
    onClose();
    setEditUserFormOpen(true);
  };

  const handleClick = (newPath) => {
    onClose();
    history.push(newPath);
  };

  const handleSignOutClick = () => {
    auth.signOut();
    onClose();
  };

  return (
    <React.Fragment>
      <Menu
        id="profile-menu"
        elevation={5}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={onClose}
        className={classes.root}
      >
        <MenuItem onClick={() => handleClick("/profile")}>
          <ProfileMenuButton displayName={displayName} />
        </MenuItem>
        <Divider />
        <MenuItem onClick={handleEditProfileClick}>
          <ListItemIcon>
            <EditIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Edit my details" />
        </MenuItem>
        <MenuItem onClick={() => handleClick("/settings")}>
          <ListItemIcon>
            <SettingsIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Settings" />
        </MenuItem>
        {isAdmin && (
          <MenuItem onClick={() => handleClick("/subscription")}>
            <ListItemIcon>
              <PaymentIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText primary="Subscription" />
          </MenuItem>
        )}
        <Divider />
        <MenuItem onClick={handleSignOutClick}>
          <ListItemIcon>
            <ExitToAppIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Sign Out" />
        </MenuItem>
      </Menu>

      {editUserFormOpen && (
        <EditUserForm
          open={editUserFormOpen}
          userId={userId}
          onCancel={handleEditCancel}
          onSave={handleEditSave}
        />
      )}

      <ControlledSnackbar state={snackbarState} onClose={handleSnackbarClose} />
    </React.Fragment>
  );
}

ProfileMenu.propTypes = {
  anchorEl: PropTypes.object,
  currentUser: PropTypes.shape({
    id: PropTypes.string,
    claims: PropTypes.shape({
      isAdmin: PropTypes.bool,
    }),
    authUser: PropTypes.shape({
      displayName: PropTypes.string,
    }),
  }),
  onClose: PropTypes.func,
  onSignOut: PropTypes.func,
};

export default ProfileMenu;
