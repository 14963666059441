/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useParams, useHistory } from "react-router-dom";
import { useAuth } from "../../hooks/use-auth";
import { NavBar } from "../../widgets";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import CloseButton from "@material-ui/icons/Close";
import Grid from "@material-ui/core/Grid";
import SignatureList from "./SignatureList.jsx";
import PhotoList from "./PhotoList.jsx";
import SampleList from "./SampleList.jsx";
import SampleMap from "./sample-map/SampleMap.jsx";
import firebase from "firebase/app";
import "firebase/firestore";
import { useDocumentData, useCollection } from "react-firebase-hooks/firestore";

const useStyles = makeStyles((theme) => ({
  gridContainer: {
    background: theme.palette.gray.verVeryLight,
    padding: theme.spacing(2),
  },
  gridRow: {
    flex: "1 1 auto",
    display: "flex",
    flexDirection: "row",
  },
  gridRowFixed: {
    height: 500,
    display: "flex",
    flexDirection: "row",
  },
  title: {
    flexGrow: 1,
  },
  paperTitle: {
    padding: theme.spacing(2),
  },
  biggerItem: {
    height: 300,
    [theme.breakpoints.up("lg")]: {
      height: 500,
    },
    display: "flex",
    flexDirection: "column",
  },
  smallerItem: {
    height: 350,
    display: "flex",
    flexDirection: "column",
  },
}));

function createSampleQuery(inspectionId, auth) {
  const samples = firebase
    .firestore()
    .collection(`inspections/${inspectionId}/samples`);

  if (auth.user.claims.isAdmin || auth.user.claims.isSuperAdmin) {
    return samples.orderBy("number");
  } else {
    return samples
      .where("organization", "==", auth.user.claims.organization)
      .where("author", "==", auth.authUser.uid)
      .orderBy("number");
  }
}

function getDocReference(inspectionId) {
  return firebase.firestore().doc(`inspections/${inspectionId}`);
}

function InspectionDetail() {
  const { id } = useParams();
  const [auth] = useAuth();
  const classes = useStyles();
  const history = useHistory();

  const [selectedSampleId, setSelectedSampleId] = useState("");
  const [inspection, loadingIgnored, error] = useDocumentData(
    getDocReference(id)
  );
  const [sampleSnapshot, sampleLoading, sampleError] = useCollection(
    createSampleQuery(id, auth)
  );

  useEffect(() => {
    const titleText = inspection?.location ?? "Loading";
    document.title = `${titleText} - Runyon Data Services`;
  }, [id, inspection?.location]);

  const handleClose = () => {
    history.push("/inspections");
  };

  const handleSampleSelected = (sampleId) => {
    setSelectedSampleId(sampleId);
  };

  return (
    <React.Fragment>
      <NavBar>
        <Toolbar>
          <Typography variant="h6" className={classes.title}>
            {inspection && inspection.location}
            {error && error.message}
          </Typography>
          <IconButton color="inherit" aria-label="close" onClick={handleClose}>
            <CloseButton />
          </IconButton>
        </Toolbar>
      </NavBar>

      {/* This div needs to be here to prevent a horizontal scroll from appearing! */}
      <div className={classes.gridContainer}>
        <Grid
          container
          spacing={2}
          alignItems="stretch"
          className={classes.container}
        >
          <Grid item xs={12} lg={5} className={classes.biggerItem}>
            <SampleList
              snapshot={sampleSnapshot}
              loading={sampleLoading}
              error={sampleError}
              selectedSampleId={selectedSampleId}
              onSampleSelected={handleSampleSelected}
            />
          </Grid>
          <Grid item xs={12} lg={7} className={classes.biggerItem}>
            <SampleMap
              snapshot={sampleSnapshot}
              selectedSampleId={selectedSampleId}
              onSampleSelected={handleSampleSelected}
            />
          </Grid>
          <Grid item xs={12} md={6} className={classes.smallerItem}>
            <PhotoList inspectionId={id} />
          </Grid>
          <Grid item xs={12} md={6} className={classes.smallerItem}>
            <SignatureList inspectionId={id} />
          </Grid>
        </Grid>
      </div>
    </React.Fragment>
  );
}

export default InspectionDetail;
