import React, { useState } from "react";
import { Switch, useHistory, useLocation } from "react-router";
import PrivateRoute from "../../widgets/PrivateRoute.jsx";
import firebase from "firebase/app";
import "firebase/firestore";
import { formatFirebaseError } from "../../data/error-formatter.js";
import { useCollection } from "react-firebase-hooks/firestore";
import { makeStyles } from "@material-ui/core/styles";
import { NavBar } from "../../widgets";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import AddIcon from "@material-ui/icons/Add";
import ControlledSnackbar from "../../widgets/ControlledSnackbar";
import EditOrganizationForm from "./EditOrganizationForm.jsx";
import OrganizationListCollapsed from "./OrganizationListCollapsed.jsx";
import OrganizationListExpanded from "./OrganizationListExpanded.jsx";

const useStyles = makeStyles((theme) => ({
  container: {
    flex: "1 1 auto",
    display: "flex",
    flexDirection: "column",
  },
  buttonRow: {
    flex: "0 1 auto",
    display: "flex",
    flexDirection: "row",
  },
  grid: {
    flex: "1 1 auto",
  },
  create: {
    flex: "0 1 auto",
    marginBottom: theme.spacing(1),
  },
  spacer: {
    flex: "1 1 auto",
  },
  orgSelect: {
    margin: theme.spacing(2),
    width: 200,
  },
  orgSelectCollapsed: {
    marginBottom: theme.spacing(2),
    marginLeft: theme.spacing(2),
    marginTop: theme.spacing(2),
    width: 200,
  },
  title: {
    flexGrow: 1,
  },
}));

function createQuery() {
  return firebase.firestore().collection("organizations");
}

function OrganizationList() {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();

  const [snackbarState, setSnackbarState] = useState({});
  const [createFormOpen, setCreateFormOpen] = useState(false);
  const [snapshot, loading, error] = useCollection(createQuery());

  const getError = () => {
    if (error === undefined) return undefined;

    console.log("Formatting error");
    return { message: formatFirebaseError(error) };
  };

  const handleShowSnackbar = (snackbarState) => {
    setSnackbarState(snackbarState);
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setSnackbarState({
      ...snackbarState,
      ["open"]: false,
    });
  };

  const handleItemSelected = (itemId) => {
    if (!itemId) {
      history.push("/organizations");
    } else {
      history.push(`/organizations/${itemId}`);
    }
  };

  const handleCreateClick = () => {
    setCreateFormOpen(true);
  };

  const onCancel = () => {
    setCreateFormOpen(false);
  };

  const onSave = (message) => {
    setCreateFormOpen(false);
    setSnackbarState({
      open: true,
      message: message,
      severity: "success",
      autoHideDuration: 6000,
    });
  };

  const showCreateButton = location.pathname == "/organizations";

  return (
    <React.Fragment>
      <NavBar>
        <Toolbar>
          <Typography variant="h6" className={classes.title}>
            Organizations
          </Typography>
          {showCreateButton && (
            <Button
              variant="contained"
              color="primary"
              startIcon={<AddIcon />}
              onClick={handleCreateClick}
            >
              Add Organization
            </Button>
          )}
        </Toolbar>
      </NavBar>
      <Switch>
        <PrivateRoute path="/organizations/:selectedId">
          <OrganizationListCollapsed
            snapshot={snapshot}
            onItemSelected={handleItemSelected}
          />
        </PrivateRoute>
        <PrivateRoute path="/organizations">
          <OrganizationListExpanded
            loading={loading}
            snapshot={snapshot}
            error={getError()}
            onItemSelected={handleItemSelected}
            onShowSnackbar={handleShowSnackbar}
          />
        </PrivateRoute>
      </Switch>

      {createFormOpen && (
        <EditOrganizationForm onCancel={onCancel} onSave={onSave} />
      )}

      <ControlledSnackbar state={snackbarState} onClose={handleSnackbarClose} />
    </React.Fragment>
  );
}

OrganizationList.propTypes = {};

export default OrganizationList;
