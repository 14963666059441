import React from "react";
import PropTypes from "prop-types";
import { PaymentElement, useElements } from "@stripe/react-stripe-js";
import { ProgressButton } from "../../widgets";
import { Typography } from "@material-ui/core";

function BillingForm(props) {
  const { saving, onSubmit } = props;

  const elements = useElements();

  const handleSubmit = async (event) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    onSubmit(elements);
  };

  return (
    <form onSubmit={handleSubmit}>
      <Typography variant="h5">Payment Information</Typography>
      <br />
      <Typography>
        {
          "Next, we'll collect payment information for your subscription, and then you can get started!"
        }
      </Typography>
      <br />
      <br />
      <PaymentElement />
      <br />
      <ProgressButton
        type="submit"
        fullWidth
        variant="contained"
        color="primary"
        loading={saving}
      >
        Continue
      </ProgressButton>
    </form>
  );
}

BillingForm.propTypes = {
  saving: PropTypes.bool,
  onSubmit: PropTypes.func,
};

export default BillingForm;
