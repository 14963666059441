import React, { useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { Row, Column, ProgressButton } from "../../widgets";
import TextField from "@material-ui/core/TextField";
import { Autocomplete } from "@react-google-maps/api";
import { extractAddress } from "../../util/autocomplete-utils";
import { useGoogleMaps } from "../../hooks/use-google-maps";
import { isPasswordValid, isEmailValid } from "../../util/data-validations";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 2,
  },
  formControl: {
    width: "100%",
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(0.5),
  },
}));

const getValidationErrors = (formState) => {
  let validationErrors = {};

  if (!formState.givenName) {
    validationErrors.givenName = "First name is required";
  }

  if (!formState.familyName) {
    validationErrors.familyName = "Last name is required";
  }

  if (!formState.email) {
    validationErrors.email = "Email is required";
  }

  if (!isEmailValid(formState.email)) {
    validationErrors.email = "Please enter a valid email address";
  }

  if (!formState.companyName) {
    validationErrors.companyName = "Company name is required";
  }

  if (!formState.street) {
    validationErrors.street = "Address Line 1 is required";
  }

  if (!formState.postalCode) {
    validationErrors.postalCode = "Postal code is required";
  }

  if (!formState.city) {
    validationErrors.city = "City is required";
  }

  if (!formState.stateProvince) {
    validationErrors.stateProvince = "State / Province is required";
  }

  if (!formState.countryRegion) {
    validationErrors.countryRegion = "Country / Region is required";
  }

  if (!formState.password) {
    validationErrors.password = "Password is required";
  }

  if (!isPasswordValid(formState.password)) {
    validationErrors.password =
      "Password must be at least 8 characters, contain an uppercase letter, a lowercase letter, and a symbol.";
  }

  if (formState.confirmPassword != formState.password) {
    validationErrors.confirmPassword = "Passwords must match";
  }

  return validationErrors;
};

const initialState = {};

function CustomerForm(props) {
  const classes = useStyles();

  const { saving, onSubmit } = props;
  const googleMaps = useGoogleMaps();
  const googleMapsLoaded = googleMaps.isLoaded;
  const [state, setState] = useState(initialState);
  const [validationErrors, setValidationErrors] = useState({});
  const [autocomplete, setAutocomplete] = useState(null);
  const [hasSubmitted, setHasSubmitted] = useState(false);

  const handleChange = (event) => {
    const name = event.target.id;
    const nextState = {
      ...state,
      [name]: event.target.value,
    };

    setState(nextState);

    if (hasSubmitted) {
      validateForm(nextState);
    }
  };

  const handleAutocompleteLoad = (autocomplete) => {
    setAutocomplete(autocomplete);
  };

  const handlePlaceChanged = () => {
    if (!autocomplete) {
      console.log("Autocomplete is not loaded yet");
      return;
    }

    const place = autocomplete.getPlace();
    const address = extractAddress(place);
    setState({
      ...state,
      ...address,
    });
  };

  const validateForm = (nextState) => {
    const validationErrors = getValidationErrors(nextState);

    setValidationErrors(validationErrors);

    return Object.keys(validationErrors).length == 0;
  };

  const handleSubmit = async (event) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    setHasSubmitted(true);
    if (!validateForm(state)) {
      return;
    }

    onSubmit(state);
  };

  return (
    <form onSubmit={handleSubmit} className={classes.root}>
      <Column padding={2}>
        <Typography>Create Your Account</Typography>
        <Row spacing={2}>
          <TextField
            autoFocus
            className={classes.formControl}
            margin="dense"
            id="givenName"
            label="First Name"
            type="text"
            variant="filled"
            value={state.givenName}
            onChange={handleChange}
            error={validationErrors.givenName}
            helperText={validationErrors.givenName}
            required
          />
          <TextField
            className={classes.formControl}
            margin="dense"
            id="familyName"
            label="Last Name"
            type="text"
            variant="filled"
            value={state.familyName}
            onChange={handleChange}
            error={validationErrors.familyName}
            helperText={validationErrors.familyName}
            required
          />
        </Row>
        <TextField
          className={classes.formControl}
          margin="dense"
          id="email"
          label="Email Address"
          type="email"
          variant="filled"
          value={state.email}
          onChange={handleChange}
          error={validationErrors.email}
          helperText={validationErrors.email}
          autoComplete="off"
          required
        />
        <TextField
          className={classes.formControl}
          margin="dense"
          id="password"
          label="Password"
          type="password"
          autoComplete="new-password"
          variant="filled"
          value={state.password}
          onChange={handleChange}
          error={validationErrors.password}
          helperText={validationErrors.password}
          required
        />
        <TextField
          className={classes.formControl}
          margin="dense"
          id="confirmPassword"
          label="Confirm Password"
          type="password"
          autoComplete="new-password"
          variant="filled"
          value={state.confirmPassword}
          onChange={handleChange}
          error={validationErrors.confirmPassword}
          helperText={validationErrors.confirmPassword}
          required
        />
        <br />
        <Typography>About Your Company</Typography>
        <TextField
          className={classes.formControl}
          margin="dense"
          id="companyName"
          label="Company Name"
          type="text"
          variant="filled"
          value={state.companyName}
          onChange={handleChange}
          error={validationErrors.companyName}
          helperText={validationErrors.companyName}
          required
        />
        {googleMapsLoaded && (
          <Autocomplete
            onLoad={handleAutocompleteLoad}
            onPlaceChanged={handlePlaceChanged}
            className={classes.formControl}
          >
            <TextField
              autoFocus
              className={classes.formControl}
              margin="dense"
              id="street"
              label="Address Line 1"
              type="text"
              variant="filled"
              value={state.street}
              onChange={handleChange}
              required
            />
          </Autocomplete>
        )}
        <TextField
          className={classes.formControl}
          margin="dense"
          id="aptUnit"
          label="Address Line 2"
          type="text"
          variant="filled"
          value={state.aptUnit}
          onChange={handleChange}
        />
        <TextField
          className={classes.formControl}
          margin="dense"
          id="city"
          label="City"
          type="text"
          variant="filled"
          value={state.city}
          onChange={handleChange}
          required
        />
        <Row spacing={2}>
          <TextField
            className={classes.formControl}
            margin="dense"
            id="stateProvince"
            label="State/Province"
            type="text"
            variant="filled"
            value={state.stateProvince}
            onChange={handleChange}
            required
          />
          <TextField
            className={classes.formControl}
            margin="dense"
            id="postalCode"
            label="Postal Code"
            type="text"
            variant="filled"
            value={state.postalCode}
            onChange={handleChange}
            required
          />
        </Row>
        <TextField
          className={classes.formControl}
          margin="dense"
          id="countryRegion"
          label="Country/Region"
          type="text"
          variant="filled"
          value={state.countryRegion}
          onChange={handleChange}
          required
        />
        <br />
        <ProgressButton
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          loading={saving}
        >
          Continue
        </ProgressButton>
      </Column>
    </form>
  );
}

CustomerForm.propTypes = {
  saving: PropTypes.bool,
  onValidationError: PropTypes.func,
  onSubmit: PropTypes.func,
};

export default CustomerForm;
