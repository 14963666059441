import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import GridCard from "../../widgets/GridCard";
import CardActions from "@material-ui/core/CardActions";
import Button from "@material-ui/core/Button";
import { Column } from "../../widgets";

const useStyles = makeStyles((theme) => ({
  addressText: {
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(0),
  },
}));

const makeCityStateZip = (organization) => {
  if (!organization?.address) return "";

  const { city, stateProvince, postalCode } = organization.address;
  return `${city},${stateProvince} ${postalCode}`;
};

function InfoCard(props) {
  const { organization, loading, canEdit, onEditClick } = props;
  const classes = useStyles();

  const apt = organization?.address?.aptUnit;

  return (
    <GridCard title="Organization Info" loading={loading}>
      <Column spacing={0} padding={2}>
        <p className={classes.addressText}>
          {organization?.address?.street ?? ""}
        </p>
        {apt && <p className={classes.addressText}>{apt}</p>}
        <p className={classes.addressText}>{makeCityStateZip(organization)}</p>
        <p className={classes.addressText}>
          {organization?.address?.countryRegion ?? ""}
        </p>
        <br />
        <p className={classes.addressText}>{organization?.phone ?? ""}</p>
        <p className={classes.addressText}>{organization?.url ?? ""}</p>
      </Column>

      {canEdit && (
        <CardActions>
          <Button size="small" color="primary" onClick={onEditClick}>
            Edit
          </Button>
        </CardActions>
      )}
    </GridCard>
  );
}

InfoCard.propTypes = {
  organization: PropTypes.shape({
    name: PropTypes.string,
    address: PropTypes.shape({
      street: PropTypes.string,
      aptUnit: PropTypes.string,
      city: PropTypes.string,
      stateProvince: PropTypes.string,
      postalCode: PropTypes.string,
      countryRegion: PropTypes.string,
    }),
    phone: PropTypes.string,
    url: PropTypes.string,
  }),
  loading: PropTypes.bool,
  canEdit: PropTypes.bool,
  onEditClick: PropTypes.func,
};

export default InfoCard;
