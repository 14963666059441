import React from "react";
import { useAuth } from "../hooks/use-auth.js";
import { Route, Redirect } from "react-router-dom";
import PropTypes from "prop-types";

function PrivateRoute({ children, render, ...rest }) {
  let [auth] = useAuth();

  const handleRender = (renderProps) => {
    const { location } = renderProps;

    if (!auth.user) {
      return (
        <Redirect
          to={{
            pathname: "/sign-in",
            state: { from: location },
          }}
        />
      );
    }

    if (render) {
      return render(renderProps);
    }

    return children;
  };

  return <Route {...rest} render={handleRender} />;
}

PrivateRoute.propTypes = {
  children: PropTypes.any,
  render: PropTypes.func,
};

export default PrivateRoute;
