import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    background: "linear-gradient(180deg, white, 10%, white, 60%, #004E89)",
    minHeight: "100vh",
    overflowY: "scroll",
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  userForm: {
    width: "30%",
    minWidth: 300,
    maxWidth: 600,
    marginTop: "auto",
    marginBottom: "auto",
  },
  logo: {
    marginTop: "auto",
    marginBottom: "auto",
    width: "30%",
    minWidth: 200,
    maxWidth: 400,
  },
}));

function getValidationErrors(formState) {
  let errors = {};

  if (!formState.email) {
    errors.email = "Email is required";
  }

  if (!formState.password) {
    errors.password = "Password is required";
  }

  // TODO: Minimum password requirements

  if (formState.password != formState.confirmPassword) {
    errors.password = "Passwords do not match";
    errors.confirmPassword = "Passwords do not match";
  }

  if (!formState.givenName) {
    errors.givenName = "First Name is required";
  }

  if (!formState.familyName) {
    errors.familyName = "Last Name is required";
  }

  return errors;
}

function AcceptInviteForm(props) {
  const classes = useStyles();

  const { user, onSubmit } = props;

  const [formState, setFormState] = useState(user);
  const [errors, setErrors] = useState({});
  const [triedSaving, setTriedSaving] = useState(false);

  useEffect(() => {
    if (triedSaving) {
      setErrors(getValidationErrors(formState));
    }
  }, [formState]);

  const handleSubmit = function () {
    setTriedSaving(true);

    const validationErrors = getValidationErrors(formState);
    if (Object.keys(validationErrors).length > 0) {
      return;
    }

    onSubmit(formState);
  };

  const handleChange = (event) => {
    const name = event.target.id;

    setFormState({
      ...formState,
      [name]: event.target.value,
    });
  };

  return (
    <React.Fragment>
      <Typography gutterBottom variant="h5" component="h2">
        Create Your Account
      </Typography>
      <TextField
        autoFocus
        className={classes.formControl}
        margin="dense"
        id="givenName"
        label="First Name"
        type="text"
        fullWidth
        variant="filled"
        required
        value={formState.givenName}
        onChange={handleChange}
        error={errors.givenName}
      />
      <TextField
        className={classes.formControl}
        margin="dense"
        id="familyName"
        label="Last Name"
        type="text"
        fullWidth
        variant="filled"
        required
        value={formState.familyName}
        onChange={handleChange}
        error={errors.familyName}
      />
      <TextField
        variant="outlined"
        margin="normal"
        required
        fullWidth
        id="email"
        label="Email Address"
        name="email"
        autoComplete="email"
        autoFocus
        value={formState.email}
        onChange={handleChange}
      />
      <TextField
        variant="outlined"
        margin="normal"
        required
        fullWidth
        name="password"
        label="Password"
        type="password"
        id="password"
        autoComplete="current-password"
        value={formState.password}
        onChange={handleChange}
      />
      <TextField
        variant="outlined"
        margin="normal"
        required
        fullWidth
        name="confirmPassword"
        label="Confirm Password"
        type="password"
        id="confirmPassword"
        autoComplete="current-password"
        value={formState.confirmPassword}
        onChange={handleChange}
      />
      <Button
        fullWidth
        variant="contained"
        color="primary"
        className={classes.submit}
        onClick={handleSubmit}
      >
        Submit
      </Button>
    </React.Fragment>
  );
}

AcceptInviteForm.propTypes = {
  user: PropTypes.shape({
    email: PropTypes.string,
    givenName: PropTypes.string,
    familyName: PropTypes.string,
  }),
  onSubmit: PropTypes.func,
};

export default AcceptInviteForm;
