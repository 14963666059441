import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useAuth } from "../../hooks";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel";
import FormGroup from "@material-ui/core/FormGroup";
import Switch from "@material-ui/core/Switch";
import { ProgressButton } from "../../widgets";
import Snackbar from "@material-ui/core/Snackbar";
import { inviteUser } from "../../data/user-store";
import Grid from "@material-ui/core/Grid";
import { SnackbarAlert, OrganizationSelector } from "../../widgets";

const useStyles = makeStyles((theme) => ({
  formControl: {
    marginBottom: theme.spacing(1),
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

function getValidationErrors(formState) {
  let errors = {};

  if (!formState.email) {
    errors.email = "Email is required";
  }

  if (!formState.organization?.id) {
    errors.organization = "Organization is required";
  }

  return errors;
}

function InviteUserForm(props) {
  const { open, onCancel, onSave } = props;
  const classes = useStyles();
  const [auth] = useAuth();

  const emptyForm = {
    email: "",
    givenName: "",
    familyName: "",
    orgId: "",
    organization: {
      id: auth.user.claims.organization,
      name: auth.user.organization,
    },
    isAdmin: false,
    isSuperAdmin: false,
  };

  const [state, setState] = useState(emptyForm);
  const [saving, setSaving] = useState(false);
  const [errors, setErrors] = useState({});
  const [snackbarState, setSnackbarState] = useState({});
  const [triedSaving, setTriedSaving] = useState(false);

  const getTitle = () => {
    return "Invite User";
  };

  const handleClose = () => {
    cleanUpState();

    onCancel();
  };

  const handleChange = (event) => {
    const name = event.target.id;

    setState({
      ...state,
      [name]: event.target.value,
    });
  };

  const handleOrganizationChange = (organization) => {
    setState({
      ...state,
      organization: organization,
    });
  };

  const handleCheckChange = (event) => {
    const name = event.target.name;

    setState({
      ...state,
      [name]: event.target.checked,
    });
  };

  // Update the validation errors if the user has tried saving
  // and do it when the form renders, not in the change handlers
  // (because they will have the old state).
  useEffect(() => {
    if (triedSaving) {
      setErrors(getValidationErrors(state));
    }
  }, [state]);

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setSnackbarState({
      ...snackbarState,
      ["open"]: false,
    });
  };

  const cleanUpState = () => {
    setErrors({});
    setTriedSaving(false);
    setState(emptyForm);
  };

  const handleSubmit = async (event) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    console.log("Form submission");
    setTriedSaving(true);

    console.log("validating");
    const validationErrors = getValidationErrors(state);
    if (Object.keys(validationErrors).length > 0) {
      console.log(JSON.stringify(validationErrors));
      setErrors(validationErrors);
      return;
    }

    setSaving(true);

    const user = {
      email: state.email,
      organization: state.organization.name,
      givenName: state.givenName,
      familyName: state.familyName,
      claims: {
        organization: state.organization.id,
        isAdmin: state.isAdmin,
        isSuperAdmin: state.isSuperAdmin,
      },
    };

    try {
      console.log("sending invite");
      console.log(JSON.stringify(user));
      const result = await inviteUser(user);

      cleanUpState();
      onSave(result);
    } catch (error) {
      console.log(JSON.stringify(error));
      setSnackbarState({
        open: true,
        message: error.message,
      });
    } finally {
      setSaving(false);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
    >
      <form onSubmit={handleSubmit}>
        <DialogTitle id="form-dialog-title">{getTitle()}</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField
                autoFocus
                className={classes.formControl}
                margin="dense"
                id="givenName"
                label="First Name"
                type="text"
                fullWidth
                variant="filled"
                required
                value={state.givenName}
                onChange={handleChange}
                error={errors.givenName}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                className={classes.formControl}
                margin="dense"
                id="familyName"
                label="Last Name"
                type="text"
                fullWidth
                variant="filled"
                required
                value={state.familyName}
                onChange={handleChange}
                error={errors.familyName}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                className={classes.formControl}
                margin="dense"
                id="email"
                label="Email Address"
                type="email"
                fullWidth
                variant="filled"
                value={state.email}
                onChange={handleChange}
                required
                error={errors.email}
                helperText={errors.email}
              />
            </Grid>
            {auth.user.claims.isSuperAdmin && (
              <Grid item xs={6}>
                <OrganizationSelector
                  organization={state.organization}
                  onChange={handleOrganizationChange}
                  error={errors.organization}
                  variant="filled"
                  fullWidth
                />
              </Grid>
            )}
            <Grid item xs={6}>
              <FormControl
                component="fieldset"
                fullWidth
                className={classes.formControl}
              >
                <FormLabel component="legend">Roles</FormLabel>
                <FormGroup>
                  {auth.user.claims.isSuperAdmin && (
                    <FormControlLabel
                      control={
                        <Switch
                          checked={state.isSuperAdmin}
                          onChange={handleCheckChange}
                          name="isSuperAdmin"
                        />
                      }
                      label="Super Admin"
                    />
                  )}
                  <FormControlLabel
                    control={
                      <Switch
                        checked={state.isAdmin}
                        onChange={handleCheckChange}
                        name="isAdmin"
                      />
                    }
                    label="Admin"
                  />
                </FormGroup>
              </FormControl>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} disabled={saving}>
            Cancel
          </Button>
          <ProgressButton type="submit" loading={saving} color="primary">
            Invite
          </ProgressButton>
        </DialogActions>
      </form>
      <Snackbar
        open={snackbarState.open}
        autoHideDuration={10000}
        onClose={handleSnackbarClose}
      >
        <SnackbarAlert onClose={handleSnackbarClose} severity="error">
          {snackbarState.message}
        </SnackbarAlert>
      </Snackbar>
    </Dialog>
  );
}

InviteUserForm.propTypes = {
  open: PropTypes.bool,
  onCancel: PropTypes.func,
  onSave: PropTypes.func,
};

export default InviteUserForm;
