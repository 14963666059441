import React, { useState } from "react";
import { Switch, matchPath } from "react-router-dom";
import clsx from "clsx";
import PrivateRoute from "../widgets/PrivateRoute.jsx";
import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import IconButton from "@material-ui/core/IconButton";
import MainToolbar from "./MainToolbar.jsx";
import Drawer from "@material-ui/core/Drawer";
import AppBar from "@material-ui/core/AppBar";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import NavMenu from "./NavMenu.jsx";
import { useAuth } from "../hooks/use-auth.js";

import {
  Dashboard,
  InspectionList,
  Users,
  OrganizationList,
  ManageSubscription,
} from "../pages";

import InspectionDetail from "../pages/inspections/InspectionDetail.jsx";
import OrganizationDetail from "../pages/organizations/OrganizationDetail.jsx";
import Paper from "@material-ui/core/Paper";

import LegalDocChecker from "./LegalDocChecker.jsx";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBarSpacer: {
    minHeight: 64,
  },
  container: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
    display: "flex",
    flexFlow: "column",
  },
  drawerContent: {
    flex: "1 1 auto",
    display: "flex",
    flexDirection: "column",
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  drawerFooter: {
    display: "flex",
    flexDirection: "row",
  },
  drawerPaper: {
    background: theme.palette.gray.light,
    position: "relative",
    whiteSpace: "nowrap",
    width: 200,
    [theme.breakpoints.up("lg")]: {
      width: 220,
    },
    [theme.breakpoints.up("xl")]: {
      width: 260,
    },
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9),
    },
  },
  spacer: {
    flex: "1 1 auto",
  },
  content: {
    flex: "1 1 auto",
    display: "flex",
    flexDirection: "row",
  },
  mainContent: {
    maxWidth: "100%",
    flex: "1 1 auto",
    display: "flex",
    flexDirection: "column",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  mainContentCollapsed: {
    background: theme.palette.gray.veryLight,
    minWidth: 240,
    maxWidth: 240,
    [theme.breakpoints.up("lg")]: {
      minWidth: 270,
      maxWidth: 270,
    },
    [theme.breakpoints.up("xl")]: {
      minWidth: 300,
      maxWidth: 300,
    },
    flex: "1 1 auto",
    display: "flex",
    flexDirection: "column",
    transition: theme.transitions.create("max-width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  detailContent: {
    display: "none",
  },
  detailContentExpanded: {
    flex: "1 1 auto",
    borderLeft: `1px solid ${theme.palette.divider}`,
    display: "flex",
    flexDirection: "column",
  },
}));

export default function MainInterface() {
  const classes = useStyles();
  const [auth] = useAuth();
  const [drawerOpen, setDrawerOpen] = useState(true);

  const match = matchPath(location.pathname, {
    path: "/:pageName/:id",
    exact: true,
    strict: true,
  });

  const detailOpen = match != null;

  const toggleDrawerState = () => {
    setDrawerOpen(!drawerOpen);
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="absolute" className={classes.appBar}>
        <MainToolbar />
      </AppBar>
      <Drawer
        variant="permanent"
        classes={{
          paper: clsx(
            classes.drawerPaper,
            !drawerOpen && classes.drawerPaperClose
          ),
        }}
        open={drawerOpen}
      >
        <div className={classes.drawerContent}>
          <div className={classes.appBarSpacer} />
          <NavMenu />
          <div className={classes.spacer} />
          <div className={classes.drawerFooter}>
            <div className={classes.spacer} />
            <IconButton onClick={toggleDrawerState}>
              {drawerOpen ? <ChevronLeftIcon /> : <ChevronRightIcon />}
            </IconButton>
          </div>
        </div>
      </Drawer>
      <main className={classes.container}>
        <div className={classes.appBarSpacer} />
        <div className={classes.content}>
          <Paper
            elevation={0}
            square
            className={clsx({
              [classes.mainContent]: !detailOpen,
              [classes.mainContentCollapsed]: detailOpen,
            })}
          >
            <Switch>
              <PrivateRoute path="/inspections">
                <InspectionList />
              </PrivateRoute>
              <PrivateRoute path="/users">
                <Users />
              </PrivateRoute>
              <PrivateRoute path="/organizations">
                <OrganizationList />
              </PrivateRoute>
              <PrivateRoute path="/devices"></PrivateRoute>
              <PrivateRoute path="/profile"></PrivateRoute>
              <PrivateRoute path="/subscription">
                <ManageSubscription
                  organizationId={auth.user.claims.organization}
                />
              </PrivateRoute>
              <PrivateRoute path="/settings">
                <OrganizationDetail
                  organizationId={auth.user.claims.organization}
                  showAsSettings
                />
              </PrivateRoute>
              <PrivateRoute path="/">
                <Dashboard />
              </PrivateRoute>
            </Switch>
          </Paper>
          <Paper
            elevation={0}
            square
            className={clsx({
              [classes.detailContent]: !detailOpen,
              [classes.detailContentExpanded]: detailOpen,
            })}
          >
            <Switch>
              <PrivateRoute path="/inspections/:id">
                <InspectionDetail />
              </PrivateRoute>
              <PrivateRoute
                path="/organizations/:id"
                render={({ match }) => {
                  return (
                    <OrganizationDetail organizationId={match.params.id} />
                  );
                }}
              />
              <PrivateRoute path="/organizations/:id">
                <OrganizationDetail />
              </PrivateRoute>
            </Switch>
          </Paper>
          <LegalDocChecker />
        </div>
      </main>
    </div>
  );
}
