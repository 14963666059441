import prodConfig from "../config.prod.json";
import devConfig from "../config.dev.json";

export default function resolveConfig() {
  const host = window.location.host;

  if (host === "app.runyondataservices.com") {
    return prodConfig;
  }

  return devConfig;
}
