import React, { useState } from "react";
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { NavBar } from "../../widgets";
import Toolbar from "@material-ui/core/Toolbar";
import { useDocumentData } from "react-firebase-hooks/firestore";
import ControlledSnackbar from "../../widgets/ControlledSnackbar";
import { Row, Column } from "../../widgets";
import SubscriptionTierCard from "../../widgets/SubscriptionTierCard";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import { ProgressButton } from "../../widgets";
import { updateSubscription } from "../../data/organization-store";
import firebase from "firebase/app";
import "firebase/firestore";
import resolveConfig from "../../util/resolve-config";

const useStyles = makeStyles((theme) => ({
  content: {
    background: theme.palette.gray.verVeryLight,
    padding: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
}));

const getQuantity = (subscription, tiers) => {
  if (!subscription?.product) {
    return null;
  }

  switch (subscription?.product) {
    case tiers.individual.priceId:
      return subscription.seats;
    case tiers.team.priceId:
      return subscription.seats / 5;
    case tiers.enterprise.priceId:
      return subscription.seats;
    default:
      1;
  }
};

const getOrgReference = (organizationId) => {
  return firebase.firestore().doc(`organizations/${organizationId}`);
};

function ManageSubscription({ organizationId }) {
  const classes = useStyles();
  const { subscriptionTiers } = resolveConfig();
  const [organization] = useDocumentData(getOrgReference(organizationId));
  const [snackbarState, setSnackbarState] = useState({});
  const [newSubscriptionQuantity, setNewSubscriptionQuantity] = useState(1);
  const [dialogState, setDialogState] = useState({ isVisible: false });
  const [updatingSubscription, setUpdatingSubscription] = useState(false);

  document.title = "Manage Subscription - Runyon Data Services";

  const currentSubscriptionProduct = organization?.subscription?.product;
  const currentSubscriptionQuantity = getQuantity(
    organization?.subscription,
    subscriptionTiers
  );

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setSnackbarState({
      ...snackbarState,
      ["open"]: false,
    });
  };

  const handleUpgradeClicked = () => {
    setDialogState({
      isVisible: true,
    });
  };

  const handleContactUsClicked = () => {};

  const handleCancel = () => {
    setDialogState({
      isVisible: false,
    });
  };

  const handleConfirm = async () => {
    setUpdatingSubscription(true);

    console.log(newSubscriptionQuantity);
    try {
      await updateSubscription(
        organizationId,
        subscriptionTiers.team.priceId,
        newSubscriptionQuantity
      );

      setDialogState({
        isVisible: false,
      });

      setSnackbarState({
        open: true,
        message:
          "Your subscription update request is processing. When it completes, your new subscription information will be shown here",
        severity: "success",
        autoHideDuration: 6000,
      });
    } catch (error) {
      setSnackbarState({
        open: true,
        message:
          "There was a problem upgrading your subscription. Please contact support.",
        severity: "warning",
        error: error,
      });
    } finally {
      setUpdatingSubscription(false);
    }
  };

  return (
    <React.Fragment>
      <NavBar>
        <Toolbar>
          <Typography variant="h6" className={classes.title}>
            Manage Subscription
          </Typography>
        </Toolbar>
      </NavBar>

      <Row padding={3} spacing={4}>
        <Column spacing={2}>
          <Typography>Current Subscription</Typography>
          <SubscriptionTierCard
            product={currentSubscriptionProduct}
            quantity={currentSubscriptionQuantity}
            disabled
            buttonDisabled
          />
        </Column>
        <Column spacing={2} justifyContent="center">
          <ArrowForwardIcon />
        </Column>
        <Column spacing={2}>
          <Typography>New Subscription</Typography>
          <Row spacing={2}>
            {organization?.subscription?.product != "custom" && (
              <SubscriptionTierCard
                product={subscriptionTiers.team.priceId}
                quantity={newSubscriptionQuantity}
                onQuantityChanged={setNewSubscriptionQuantity}
                buttonText="Change"
                onSelected={handleUpgradeClicked}
              />
            )}
            <SubscriptionTierCard
              product="custom"
              quantity={1}
              onSelected={handleContactUsClicked}
            />
          </Row>
        </Column>
      </Row>
      <Dialog open={dialogState.isVisible}>
        <DialogTitle>Upgrade Subscription</DialogTitle>
        <DialogContent>
          <Typography>{`By clicking 'Confirm', your subscription will be updated and your payment method on file will be charged the difference in price for the remainder of the subscription period. At the beginning of the next subscription period, and any subsquent subscription periods, you will be charged the new amount.`}</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancel} color="primary">
            Cancel
          </Button>
          <ProgressButton
            onClick={handleConfirm}
            color="primary"
            loading={updatingSubscription}
          >
            Confirm
          </ProgressButton>
        </DialogActions>
      </Dialog>
      <ControlledSnackbar state={snackbarState} onClose={handleSnackbarClose} />
    </React.Fragment>
  );
}

ManageSubscription.propTypes = {
  organizationId: PropTypes.string,
};

export default ManageSubscription;
