import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useLocation } from "react-router-dom";
import { useStripeInstance } from "../../hooks/stripe-hooks";
import { Elements } from "@stripe/react-stripe-js";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CircularProgress from "@material-ui/core/CircularProgress";
import ControlledSnackbar from "../../widgets/ControlledSnackbar";
import BillingForm from "./BillingForm";

const useStyles = makeStyles(() => ({
  root: {
    width: "50%",
    minWidth: 300,
    maxWidth: 600,
    marginTop: "auto",
    marginBottom: "auto",
  },
}));

export default function SetupBilling() {
  const classes = useStyles();
  const location = useLocation();

  const query = new URLSearchParams(location.search);
  const clientSecret = query.get("clientSecret");

  const [stripe, loading] = useStripeInstance();
  const [snackbarState, setSnackbarState] = useState({});
  const [saving, setSaving] = useState(false);

  const stripeOptions = {
    clientSecret: clientSecret,
    // subscription && subscription.latest_invoice.payment_intent.client_secret,
  };

  const showError = (error) => {
    setSnackbarState({
      open: true,
      message: error.message,
      severity: "error",
      error: error,
    });
  };

  const handleSubmit = async (elements) => {
    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setSaving(true);

    const { error } = await stripe.confirmPayment({
      //`Elements` instance that was used to create the Payment Element
      elements,
      confirmParams: {
        return_url: `${window.location.origin}/sign-up/payment-complete`,
      },
    });

    if (error) {
      // This point will only be reached if there is an immediate error when
      // confirming the payment. Show error to your customer (for example, payment
      // details incomplete)
      showError(error);
    } else {
      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
    }

    setSaving(false);
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setSnackbarState({
      ...snackbarState,
      ["open"]: false,
    });
  };

  return (
    <Card className={classes.root}>
      <CardContent>
        {loading ? (
          <CircularProgress />
        ) : (
          <Elements stripe={stripe} options={stripeOptions}>
            <BillingForm saving={saving} onSubmit={handleSubmit} />
          </Elements>
        )}
        <ControlledSnackbar
          state={snackbarState}
          onClose={handleSnackbarClose}
        />
      </CardContent>
    </Card>
  );
}
