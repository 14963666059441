const extractStreet = (place) => {
  const streetNumber = place.address_components.find((e) =>
    e.types.includes("street_number")
  );
  const route = place.address_components.find((e) => e.types.includes("route"));

  return `${streetNumber.long_name} ${route.short_name}`;
};

const extractCity = (place) => {
  const locality = place.address_components.find((e) =>
    e.types.includes("locality")
  );

  return locality.long_name;
};

const extractStateProvince = (place) => {
  const adminArea1 = place.address_components.find((e) =>
    e.types.includes("administrative_area_level_1")
  );

  return adminArea1.short_name;
};

const extractPostalCode = (place) => {
  const postalCode = place.address_components.find((e) =>
    e.types.includes("postal_code")
  );

  return postalCode.long_name;
};

const extractCountryRegion = (place) => {
  const country = place.address_components.find((e) =>
    e.types.includes("country")
  );

  return country.long_name;
};

const extractAddress = (place) => {
  return {
    street: extractStreet(place),
    city: extractCity(place),
    stateProvince: extractStateProvince(place),
    postalCode: extractPostalCode(place),
    countryRegion: extractCountryRegion(place),
  };
};

export { extractAddress };
