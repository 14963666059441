import React, { useEffect, useState } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { verifyUserEmail } from "../../data/user-store";
import { CircularProgress } from "@material-ui/core";
import { Column } from "../../widgets";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import ErrorIcon from "@material-ui/icons/Error";
import { Typography } from "@material-ui/core";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { green, yellow } from "@material-ui/core/colors";

const useStyles = makeStyles(() => ({
  root: {
    width: "50%",
    minWidth: 300,
    maxWidth: 600,
    marginTop: "auto",
    marginBottom: "auto",
  },
}));

const useEmailVerification = (token) => {
  const [clientSecret, setClientSecret] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(async () => {
    setLoading(true);

    try {
      const userData = await verifyUserEmail(token);

      setClientSecret(userData.signUp.clientSecret);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  }, [token]);

  return [clientSecret, loading, error];
};

export default function VerifyEmail() {
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();

  const query = new URLSearchParams(location.search);
  const token = query.get("token");

  const [clientSecret, loading, error] = useEmailVerification(token);

  const handleContinueClicked = () => {
    history.replace(`/sign-up/setup-billing?clientSecret=${clientSecret}`);
  };

  return (
    <Card className={classes.root}>
      <CardContent>
        {loading && (
          <Column horizontalAlignment="centerHorizontally">
            <CircularProgress />
            <Typography>Please wait...</Typography>
          </Column>
        )}
        {error && (
          <Column horizontalAlignment="centerHorizontally">
            <ErrorIcon style={{ color: yellow[500] }} />
            <Typography>
              Something went wrong. Please try again or contact support.
            </Typography>
          </Column>
        )}
        {clientSecret && (
          <Column horizontalAlignment="centerHorizontally">
            <CheckCircleIcon style={{ color: green[500], fontSize: 100 }} />
            <Typography>Email verified.</Typography>
            <br />
            <Button
              fullWidth
              variant="contained"
              color="primary"
              onClick={handleContinueClicked}
            >
              Continue to Payment
            </Button>
          </Column>
        )}
      </CardContent>
    </Card>
  );
}
