/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useContext, createContext } from "react";
import firebase from "firebase/app";
import "firebase/auth";
import resolveConfig from "../util/resolve-config";

import PropTypes from "prop-types";

const config = resolveConfig();
firebase.initializeApp(config.firebase);

const authContext = createContext();
// Provider component that wraps your app and makes auth object
// available to any child component that calls useAuth().
export function AuthProvider({ children }) {
  const auth = useProvideAuth();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

AuthProvider.propTypes = {
  children: PropTypes.any,
};

// Hook for child components to get the auth object
// and re-render when it changes.
export const useAuth = () => {
  return useContext(authContext);
};
// Provider hook that creates auth object and handles state
function useProvideAuth() {
  const [user, setUser] = useState(null);
  const [authUser, setAuthUser] = useState(null);

  const [status, setStatus] = useState("initializing");
  const [error, setError] = useState(null);

  const setUserWithClaims = async (user) => {
    try {
      const idTokenResult = await user.getIdTokenResult();

      if (idTokenResult.claims.organizationDisabled) {
        throw new Error("Organization is disabled");
      }

      const userSnap = await firebase
        .firestore()
        .doc(`users/${user.uid}`)
        .get();
      const userData = userSnap.data();

      setAuthUser(user);
      setUser(userData);

      console.log("User set. Status = " + status);
    } catch (error) {
      setError(error);
    }
  };

  // Wrap any Firebase methods we want to use making sure
  // to save the user to state.
  const signIn = async (email, password) => {
    try {
      console.log("firebase auth");
      const userCredential = await firebase
        .auth()
        .signInWithEmailAndPassword(email, password);
    } catch (error) {
      setError(error);
    }
  };

  // const signUp = (email, password) => {
  //   return firebase
  //     .auth()
  //     .createUserWithEmailAndPassword(email, password)
  //     .then((response) => {
  //       setUser(response.user);
  //     });
  // };

  const signOut = async () => {
    try {
      await firebase.auth().signOut();

      setUser(false);
      setStatus("signedOut");
    } catch (error) {
      setError(error);
    }
  };

  const sendPasswordResetEmail = async (email) => {
    try {
      await firebase.auth().sendPasswordResetEmail(email);
    } catch (error) {
      setError(error);
    }
  };

  // Subscribe to user on mount
  // Because this sets state in the callback it will cause any
  // component that utilizes this hook to re-render with the
  // latest auth object.
  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        setUserWithClaims(user);
        setStatus("signedIn");
      } else {
        setUser(false);
        setStatus("signedOut");
      }
    });
    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, []);
  // Return the user object and auth methods
  return [
    {
      status,
      user,
      authUser,
      error,
      signIn,
      signOut,
      sendPasswordResetEmail,
    },
  ];
}
