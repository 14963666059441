import { useState, useEffect } from "react";
import firebase from "firebase/app";

export function useOrganization(organizationId) {
  const [organization, setOrganization] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const db = firebase.firestore();

  useEffect(async () => {
    setLoading(true);

    try {
      const snap = await db.doc(`organizations/${organizationId}`).get();

      if (!snap.exists) {
        throw new Error("Organization not found");
      }

      setOrganization(snap.data());
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  }, [organizationId]);

  return [organization, loading, error];
}

export function useSubscription(organizationId) {
  const [subscription, setSubscription] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const db = firebase.firestore();

  useEffect(async () => {
    setLoading(true);

    try {
      const snap = await db.doc(`organizations/${organizationId}`).get();

      if (!snap.exists) {
        throw new Error("Organization not found");
      }

      const organization = snap.data();

      const activeUsers = await db
        .collection("users")
        .where("claims.organization", "==", organizationId)
        .where("disabled", "==", false)
        .get();

      setSubscription({
        totalSeats: organization.subscription.seats,
        usedSeats: activeUsers.size,
        availableSeats: organization.subscription.seats - activeUsers.size,
      });
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  }, [organizationId]);

  return [subscription, loading, error];
}
