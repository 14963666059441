import React, { useState } from "react";
import PropTypes from "prop-types";
import Snackbar from "@material-ui/core/Snackbar";
import SnackbarAlert from "./SnackbarAlert";
import { Button, IconButton } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContentText from "@material-ui/core/DialogContentText";

function ControlledSnackbar(props) {
  const { state, onClose } = props;

  const [dialogOpen, setDialogOpen] = useState(false);

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    onClose();
  };

  const handleErrorDetailsClick = () => {
    handleSnackbarClose();

    setDialogOpen(true);
  };

  const handleErrorDetailsClose = () => {
    setDialogOpen(false);
  };

  return (
    <React.Fragment>
      <Snackbar
        open={state.open}
        autoHideDuration={state.autoHideDuration}
        onClose={handleSnackbarClose}
      >
        <SnackbarAlert
          severity={state.severity}
          action={
            <React.Fragment>
              {state.error && (
                <Button
                  color="secondary"
                  size="small"
                  onClick={handleErrorDetailsClick}
                >
                  DETAILS
                </Button>
              )}
              <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={handleSnackbarClose}
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            </React.Fragment>
          }
        >
          {state.message}
        </SnackbarAlert>
      </Snackbar>

      <Dialog open={dialogOpen}>
        <DialogTitle id="error-details-title">Error Details</DialogTitle>
        <DialogContent>
          <DialogContentText id="error-details-text">
            {JSON.stringify(state.error)}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleErrorDetailsClose} color="primary">
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

ControlledSnackbar.propTypes = {
  state: PropTypes.shape({
    open: PropTypes.bool,
    severity: PropTypes.string,
    error: PropTypes.object,
    message: PropTypes.string,
    autoHideDuration: PropTypes.number,
  }),
  onClose: PropTypes.func,
};

export default ControlledSnackbar;
