import React from "react";
import PropTypes from "prop-types";
import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/storage";
import { makeStyles } from "@material-ui/core/styles";
import { useDownloadURL } from "react-firebase-hooks/storage";
import CircularProgress from "@material-ui/core/CircularProgress";
import Tooltip from "@material-ui/core/Tooltip";
import Avatar from "@material-ui/core/Avatar";
import WarningIcon from "@material-ui/icons/Warning";
import { amber } from "@material-ui/core/colors";

const useStyles = makeStyles((theme) => ({
  image: (props) => ({
    maxWidth: props.width,
    maxHeight: props.height,
  }),
  error: {
    color: theme.palette.getContrastText(amber[500]),
    backgroundColor: amber[500],
  },
}));

function FirebaseImage(props) {
  const { storagePath } = props;
  const classes = useStyles(props);
  const ref = firebase.storage().ref(storagePath);
  const [downloadUrl, loading, error] = useDownloadURL(ref);

  return (
    <div>
      {loading && <CircularProgress />}
      {!loading && !error && (
        <img className={classes.image} src={downloadUrl} />
      )}
      {!loading && error && (
        <Tooltip title={error.message}>
          <Avatar className={classes.error}>
            <WarningIcon />
          </Avatar>
        </Tooltip>
      )}
    </div>
  );
}

FirebaseImage.propTypes = {
  storagePath: PropTypes.string,
  height: PropTypes.number,
  width: PropTypes.number,
};

export default FirebaseImage;
