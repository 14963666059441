import React, { useCallback, useState } from "react";
import PropTypes from "prop-types";
import GridCard from "../../../widgets/GridCard.jsx";
import { GoogleMap } from "@react-google-maps/api";
import SampleMarker from "./SampleMarker.jsx";
import { useGoogleMaps } from "../../../hooks/use-google-maps.js";

function getMapParams(snapshot) {
  const indianapolis = {
    center: {
      lat: 39.791,
      lng: -86.148003,
    },
    zoom: 6,
  };

  if (!snapshot || snapshot.docs.length == 0 || !window.google?.maps) {
    return indianapolis;
  }

  let gBounds = new window.google.maps.LatLngBounds();

  snapshot.docs.forEach((doc) => {
    const data = doc.data();

    if (!data.lat || !data.lng) {
      return;
    }

    gBounds.extend({
      lat: data.lat,
      lng: data.lng,
    });
  });

  const center = {
    lat: gBounds.getCenter().lat(),
    lng: gBounds.getCenter().lng(),
  };

  // If the center is here, then there weren't actually any samples with
  // GPS coords.
  if (center.lat === 0 && center.lng === -180) {
    return indianapolis;
  }
  return { center: center, zoom: 18 };
}

const containerStyle = {
  width: "100%",
  height: "100%",
};

function SampleMap(props) {
  const { snapshot, selectedSampleId, onSampleSelected } = props;
  const { center, zoom } = getMapParams(snapshot);

  const { isLoaded } = useGoogleMaps();

  // eslint-disable-next-line no-unused-vars
  const [map, setMap] = useState(null);

  const onLoad = useCallback(function callback(map) {
    const bounds = new window.google.maps.LatLngBounds();
    map.fitBounds(bounds);
    setMap(map);
  }, []);

  const onUnmount = useCallback(function callback() {
    setMap(null);
  }, []);

  const onMarkerClick = useCallback(function (id) {
    if (onSampleSelected) {
      onSampleSelected(id);
    }
  }, []);

  const renderMarker = (doc) => {
    const data = doc.data();
    const selected = doc.id == selectedSampleId;

    if (!data.lat || !data.lng) return null;

    return (
      <SampleMarker
        key={doc.id}
        id={doc.id}
        number={data.number}
        position={{ lat: data.lat, lng: data.lng }}
        selected={selected}
        onClick={onMarkerClick}
      />
    );
  };

  return (
    <GridCard>
      {isLoaded && (
        <GoogleMap
          mapContainerStyle={containerStyle}
          options={{
            streetViewControl: false,
            fullscreenControl: false,
            mapTypeControl: false,
          }}
          center={center}
          zoom={zoom}
          onLoad={onLoad}
          onUnmount={onUnmount}
          onChildClick={onMarkerClick}
        >
          {snapshot &&
            snapshot.docs.map(renderMarker).filter((marker) => marker)}
        </GoogleMap>
      )}
    </GridCard>
  );
}

SampleMap.propTypes = {
  loading: PropTypes.bool,
  error: PropTypes.object,
  snapshot: PropTypes.object,
  selectedSampleId: PropTypes.string,
  onSampleSelected: PropTypes.func,
};

export default SampleMap;
