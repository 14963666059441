import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import ButtonGroup from "@material-ui/core/ButtonGroup";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    gap: theme.spacing(2),
    minHeight: 60,
  },
}));

const getValueText = (value, minUsers, maxUsers, disabled) => {
  if (!value) return "";

  if (disabled && maxUsers === Number.POSITIVE_INFINITY && value == 1) {
    return `${minUsers}+ users`;
  }

  if (value == 1) {
    return "1 user";
  }

  return `${value} users`;
};

function UserWidget(props) {
  const classes = useStyles();
  const { minUsers, maxUsers, stepSize, disabled, value, setValue } = props;

  const decreaseUsers = () => {
    setValue(Math.max(minUsers, value - stepSize));
  };

  const increaseUsers = () => {
    setValue(Math.min(maxUsers, value + stepSize));
  };

  const valueText = getValueText(value, minUsers, maxUsers, disabled);

  return (
    <div className={classes.root}>
      <Typography>{valueText}</Typography>
      {!disabled && (
        <ButtonGroup variant="outlined">
          <Button
            size="small"
            aria-label="decrease users"
            onClick={decreaseUsers}
          >
            <RemoveIcon />
          </Button>
          <Button
            size="small"
            aria-label="increase users"
            onClick={increaseUsers}
          >
            <AddIcon />
          </Button>
        </ButtonGroup>
      )}
    </div>
  );
}

UserWidget.propTypes = {
  minUsers: PropTypes.number,
  maxUsers: PropTypes.number,
  stepSize: PropTypes.number,
  disabled: PropTypes.bool,
  value: PropTypes.number,
  setValue: PropTypes.func,
};

export default UserWidget;
