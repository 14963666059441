import React from "react";
import PropTypes from "prop-types";
import Avatar from "@material-ui/core/Avatar";
import Row from "../widgets/Row";
import Column from "../widgets/Column";
import { Typography } from "@material-ui/core";

const makeInitials = (displayName) => {
  if (!displayName) return "";

  const segments = displayName.split(" ");

  return segments
    .map((seg) => {
      if (seg.length == 0) return "";

      return seg.charAt(0);
    })
    .join("")
    .toUpperCase();
};

function ProfileMenuButton(props) {
  const { displayName } = props;

  const avatarText = makeInitials(displayName);

  return (
    <Row spacing={2} verticalAlignment="centerVertically">
      <Avatar>{avatarText}</Avatar>
      <Column>
        <Typography variant="h6">{`${displayName}`}</Typography>
        <Typography color="textSecondary">View profile</Typography>
      </Column>
    </Row>
  );
}

ProfileMenuButton.propTypes = {
  displayName: PropTypes.string,
};

export default ProfileMenuButton;
