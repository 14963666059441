import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import Snackbar from "@material-ui/core/Snackbar";
import { SnackbarAlert } from "../../widgets";
import { Row, Column } from "../../widgets";
import { Autocomplete } from "@react-google-maps/api";
import { extractAddress } from "../../util/autocomplete-utils";

import {
  getOrganization,
  putOrganization,
} from "../../data/organization-store";
import { useGoogleMaps } from "../../hooks/use-google-maps";

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: "100%",
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(0.5),
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

function getValidationErrors(formState) {
  let errors = {};

  if (!formState.name) {
    errors.name = "Name is required";
  }

  return errors;
}

function mapFormStateToOrganization(state, organization) {
  const orgCopy = {};
  Object.assign(orgCopy, organization);

  orgCopy.name = state.name ?? "";
  orgCopy.address = {
    street: state.street ?? "",
    aptUnit: state.aptUnit ?? "",
    city: state.city ?? "",
    stateProvince: state.stateProvince ?? "",
    postalCode: state.postalCode ?? "",
    countryRegion: state.countryRegion ?? "",
  };
  orgCopy.phone = state.phone ?? "";
  orgCopy.url = state.url ?? "";

  return orgCopy;
}

function mapOrganizationToFormState(organization) {
  if (!organization) return initialState;

  return {
    name: organization.name ?? "",
    street: organization.address?.street ?? "",
    aptUnit: organization.address?.aptUnit ?? "",
    city: organization.address?.city ?? "",
    stateProvince: organization.address?.stateProvince ?? "",
    postalCode: organization.address?.postalCode ?? "",
    countryRegion: organization.address?.countryRegion ?? "",
    phone: organization.phone ?? "",
    url: organization.url ?? "",
  };
}

const initialState = {
  name: "",
  street: "",
  aptUnit: "",
  city: "",
  stateProvince: "",
  postalCode: "",
  countryRegion: "",
  timezone: "",
  phone: "",
  url: "",
};

//const timezoneOptions = [];

function EditOrganizationForm(props) {
  const { organizationId, onCancel, onSave } = props;
  const classes = useStyles();

  const { isLoaded } = useGoogleMaps();

  const [organization, setOrganization] = useState(null);
  const [state, setState] = useState(initialState);
  const [saving, setSaving] = useState(false);
  const [errors, setErrors] = useState({});
  const [snackbarState, setSnackbarState] = useState({});
  const [autocomplete, setAutocomplete] = useState(null);

  useEffect(async () => {
    if (organizationId) {
      try {
        const org = await getOrganization(organizationId);

        setOrganization(org);
        setState(mapOrganizationToFormState(org));
      } catch (error) {
        setSnackbarState({
          open: true,
          severity: "error",
          message: error.message,
        });
      }
    }
  }, [organizationId]);

  const getTitle = () => {
    if (organizationId) {
      return "Edit Organization";
    } else {
      return "Add Organization";
    }
  };

  const handleLoad = (autocomplete) => {
    setAutocomplete(autocomplete);
  };

  const handleClose = () => {
    onCancel();
  };

  const handleChange = (event) => {
    const name = event.target.id;

    setState({
      ...state,
      [name]: event.target.value,
    });
  };

  const handlePlaceChanged = () => {
    if (!autocomplete) {
      console.log("Autocomplete is not loaded yet");
      return;
    }

    const place = autocomplete.getPlace();
    const address = extractAddress(place);

    setState(...state, ...address);
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setSnackbarState({
      ...snackbarState,
      ["open"]: false,
    });
  };

  const handleSave = async () => {
    const validationErrors = getValidationErrors(state);
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    setSaving(true);

    try {
      const result = await putOrganization(
        mapFormStateToOrganization(state, organization),
        organizationId
      );

      onSave({
        open: true,
        message: result.message,
        severity: "success",
        autoHideDuration: 6000,
      });
    } catch (error) {
      setSnackbarState({
        open: true,
        message: error.message,
      });
    } finally {
      setSaving(false);
    }
  };

  return (
    <Dialog
      open={true}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
    >
      <Backdrop className={classes.backdrop} open={saving}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <DialogTitle id="form-dialog-title">{getTitle()}</DialogTitle>
      <DialogContent>
        <Column padding={2}>
          <TextField
            className={classes.formControl}
            margin="dense"
            id="name"
            label="Name"
            type="text"
            variant="filled"
            value={state.name}
            onChange={handleChange}
            error={errors.name}
            helperText={errors.name}
          />
          {isLoaded && (
            <Autocomplete
              onLoad={handleLoad}
              onPlaceChanged={handlePlaceChanged}
              className={classes.formControl}
            >
              <TextField
                autoFocus
                className={classes.formControl}
                margin="dense"
                id="street"
                label="Address"
                type="text"
                variant="filled"
                value={state.street}
                onChange={handleChange}
              />
            </Autocomplete>
          )}
          <TextField
            className={classes.formControl}
            margin="dense"
            id="aptUnit"
            label="Unit, suite, or floor #"
            type="text"
            variant="filled"
            value={state.aptUnit}
            onChange={handleChange}
          />
          <TextField
            className={classes.formControl}
            margin="dense"
            id="city"
            label="City"
            type="text"
            variant="filled"
            value={state.city}
            onChange={handleChange}
          />
          <Row spacing={2}>
            <TextField
              className={classes.formControl}
              margin="dense"
              id="stateProvince"
              label="State/Province"
              type="text"
              variant="filled"
              value={state.stateProvince}
              onChange={handleChange}
            />
            <TextField
              className={classes.formControl}
              margin="dense"
              id="postalCode"
              label="Postal code"
              type="text"
              variant="filled"
              value={state.postalCode}
              onChange={handleChange}
            />
          </Row>
          <TextField
            className={classes.formControl}
            margin="dense"
            id="countryRegion"
            label="Country/Region"
            type="text"
            variant="filled"
            value={state.countryRegion}
            onChange={handleChange}
          />
          {/* <FormControl variant="filled" className={classes.formControl}>
            <InputLabel id="timezone-label">Default Time Zone</InputLabel>
            <Select labelId="timezone-label" id="timezone">
              {timezoneOptions}
            </Select>
          </FormControl> */}
          <TextField
            className={classes.formControl}
            margin="dense"
            id="phone"
            label="Phone"
            type="text"
            variant="filled"
            value={state.phone}
            onChange={handleChange}
          />
          <TextField
            className={classes.formControl}
            margin="dense"
            id="url"
            label="Website/Url"
            type="text"
            variant="filled"
            value={state.url}
            onChange={handleChange}
          />
        </Column>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} disabled={saving}>
          Cancel
        </Button>
        <Button onClick={handleSave} disabled={saving} color="primary">
          Save
        </Button>
      </DialogActions>
      <Snackbar
        open={snackbarState.open}
        autoHideDuration={10000}
        onClose={handleSnackbarClose}
      >
        <SnackbarAlert onClose={handleSnackbarClose} severity="error">
          {snackbarState.message}
        </SnackbarAlert>
      </Snackbar>
    </Dialog>
  );
}

EditOrganizationForm.propTypes = {
  organizationId: PropTypes.string,
  onCancel: PropTypes.func,
  onSave: PropTypes.func,
};

export default EditOrganizationForm;
