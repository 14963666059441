import React, { useState, useEffect } from "react";
import { useAuth } from "../../hooks/use-auth";
import firebase from "firebase/app";
import "firebase/firestore";
import { useCollection } from "react-firebase-hooks/firestore";
import { makeStyles } from "@material-ui/core/styles";
import InviteUserForm from "./InviteUserForm.jsx";
import EditUserForm from "./EditUserForm";
import { DataGrid } from "@material-ui/data-grid";
import { NavBar } from "../../widgets";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import ActivateUserButton from "./ActivateUserButton";
import DeactivateUserButton from "./DeactivateUserButton";
import ControlledSnackbar from "../../widgets/ControlledSnackbar";
import InviteUserButton from "./InviteUserButton";
import CheckIcon from "@material-ui/icons/Check";
import UserListControl from "./UserListControl";
import ResendInvitationButton from "./ResendInvitationButton";
import { hasAvailableSeats } from "../../data/organization-store";

const useStyles = makeStyles((theme) => ({
  grid: {
    flex: "1 1 auto",
    margin: theme.spacing(2),
    "&.MuiDataGrid-root .MuiDataGrid-cell:focus": {
      outline: "none",
    },
  },
  orgSelect: {
    margin: theme.spacing(2),
    width: 200,
  },
  title: {
    flexGrow: 1,
  },
}));

const isExpired = (invitation) => {
  const { createdAt, lifetime } = invitation;

  const createdDate = createdAt?.toDate();
  const now = Date.now();

  const hoursSinceInvite = Math.abs(now - createdDate) / 36e5;

  return hoursSinceInvite < 0 || hoursSinceInvite > lifetime;
};

function makeColumns(
  isSuperAdmin,
  showDisabledUsers,
  makeDeleteButton,
  makeStatusCell
) {
  const makeCheckMark = (params) => {
    return <React.Fragment>{params.value && <CheckIcon />}</React.Fragment>;
  };

  let columns = [
    {
      field: "givenName",
      headerName: "First Name",
      width: 150,
    },
    {
      field: "familyName",
      headerName: "Last Name",
      width: 150,
    },
    {
      field: "email",
      headerName: "Email",
      width: 250,
    },
    {
      field: "status",
      headerName: "Status",
      width: 250,
      renderCell: makeStatusCell,
    },
    {
      field: "isAdmin",
      headerName: "Admin",
      renderCell: makeCheckMark,
      width: 125,
    },
  ];

  if (isSuperAdmin) {
    columns.push({
      field: "isSuperAdmin",
      headerName: "SuperAdmin",
      renderCell: makeCheckMark,
      width: 175,
    });
  }

  if (showDisabledUsers) {
    columns.push({
      field: "disabled",
      headerName: "Deactivated",
      renderCell: makeCheckMark,
      width: 175,
    });
  }

  columns.push({
    field: "delete",
    headerName: "Delete",
    renderCell: makeDeleteButton,
    disableColumnMenu: true,
    sortable: false,
    width: 150,
  });

  return columns;
}

function mapSnapshotToRows(snapshot) {
  if (!snapshot) return [];

  const rows = snapshot.docs.map((doc) => {
    const data = doc.data();
    return {
      id: doc.id,
      email: data.email,
      givenName: data.givenName,
      familyName: data.familyName,
      isAdmin: data.claims?.isAdmin,
      isSuperAdmin: data.claims?.isSuperAdmin,
      disabled: data.disabled,
      status: {
        email: data.email,
        invitation: data.invitation,
      },
      delete: {
        userId: doc.id,
        email: data.email,
        disabled: data.disabled,
      },
    };
  });

  return rows;
}

function createQuery(organization, showDisabledUsers) {
  const query = firebase
    .firestore()
    .collection("users")
    .where("claims.organization", "==", organization.id);

  if (showDisabledUsers) {
    return query;
  } else {
    return query.where("disabled", "==", false);
  }
}

export default function Users() {
  const classes = useStyles();

  useEffect(() => {
    document.title = "Users - Runyon Data Services";
  });

  const [auth] = useAuth();
  const [organization, setOrganization] = useState({
    id: auth.user.claims.organization,
    name: auth.user.organization,
  });
  const [editFormState, setEditFormState] = useState({});
  const [inviteFormOpen, setInviteFormOpen] = useState(false);
  const [snackbarState, setSnackbarState] = useState({});
  const [selectionModel, setSelectionModel] = useState([]);
  const [showDisabledUsers, setShowDisabledUsers] = useState(false);
  const [query, setQuery] = useState(createQuery(organization, false));

  const [snapshot, loading, error] = useCollection(query);

  if (error) {
    console.log(JSON.stringify(error));
  }
  const handleOrganizationChange = (organization) => {
    setOrganization(organization);

    setQuery(createQuery(organization, showDisabledUsers));
  };

  const handleShowDisabledUsersChange = (event) => {
    setShowDisabledUsers(event.target.checked);

    setQuery(createQuery(organization, event.target.checked));
  };

  const handleInviteClick = async () => {
    const canInvite = await hasAvailableSeats(organization);

    if (canInvite) {
      setInviteFormOpen(true);
    } else {
      setSnackbarState({
        open: true,
        message:
          "There are no more seats available to invite new users. If you want to invite more, please upgrade your subscription.",
        severity: "warning",
      });
    }
  };

  const handleInviteCancel = () => {
    setInviteFormOpen(false);
  };

  const handleInviteSave = (message) => {
    setInviteFormOpen(false);
    setSnackbarState({
      open: true,
      message: message,
      severity: "success",
      autoHideDuration: 6000,
    });
  };

  const handleCreateClick = () => {
    setEditFormState({
      open: true,
      userId: null,
    });
  };

  const handleSnackbarClose = () => {
    setSnackbarState({
      ...snackbarState,
      ["open"]: false,
    });
  };

  const handleSelectionModelChange = (selectionModel) => {
    setSelectionModel(selectionModel);

    const selectedUserId =
      selectionModel.length > 0 ? selectionModel[0] : undefined;

    setEditFormState({
      open: true,
      userId: selectedUserId,
    });
  };

  const handleCellClick = (params, event) => {
    if (params.colDef.field == "delete" || params.colDef.field == "status") {
      event.stopPropagation();
    }
  };

  const handleEditCancel = () => {
    setSelectionModel([]);
    setEditFormState({});
  };

  const handleEditSave = (state) => {
    setSelectionModel([]);
    setEditFormState({});
    setSnackbarState(state);
  };

  const makeDeleteButton = (params) => {
    const { disabled, userId, email } = params.value;

    if (disabled) {
      return (
        <ActivateUserButton userId={userId} onShowSnackbar={setSnackbarState} />
      );
    } else {
      return (
        <DeactivateUserButton
          enableHardDelete={auth.user.claims.isSuperAdmin}
          userId={userId}
          email={email}
          onShowSnackbar={setSnackbarState}
        />
      );
    }
  };

  const makeStatusCell = (params) => {
    const { invitation, email } = params.value;

    if (!invitation) {
      return <br />;
    }
    const text = isExpired(invitation) ? "Invitation Expired" : "Invited";

    return (
      <React.Fragment>
        <Typography>{text}</Typography>
        <ResendInvitationButton
          email={email}
          onShowSnackbar={setSnackbarState}
        />
      </React.Fragment>
    );
  };

  const columns = makeColumns(
    auth.user.claims.isSuperAdmin,
    showDisabledUsers,
    makeDeleteButton,
    makeStatusCell
  );

  return (
    <React.Fragment>
      <NavBar>
        <Toolbar>
          <Typography variant="h6" className={classes.title}>
            People
          </Typography>
          <InviteUserButton
            enableHardCreate={auth.user.claims.isSuperAdmin}
            onInviteUserClick={handleInviteClick}
            onCreateUserClick={handleCreateClick}
          />
        </Toolbar>
      </NavBar>
      <UserListControl
        showDisabledUsers={showDisabledUsers}
        enableOrgSelector={auth.user.claims.isSuperAdmin}
        selectedOrganization={organization}
        onShowDisabledUsersChange={handleShowDisabledUsersChange}
        onOrganizationChange={handleOrganizationChange}
      />

      <DataGrid
        className={classes.grid}
        rows={mapSnapshotToRows(snapshot)}
        columns={columns}
        loading={loading}
        error={error}
        onSelectionModelChange={handleSelectionModelChange}
        selectionModel={selectionModel}
        onCellClick={handleCellClick}
      />

      <InviteUserForm
        open={inviteFormOpen}
        onCancel={handleInviteCancel}
        onSave={handleInviteSave}
      />

      {editFormState.open && (
        <EditUserForm
          userId={editFormState.userId}
          onCancel={handleEditCancel}
          onSave={handleEditSave}
        />
      )}

      <ControlledSnackbar state={snackbarState} onClose={handleSnackbarClose} />
    </React.Fragment>
  );
}
