import React from "react";
import PropTypes from "prop-types";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: 16,
  },
  image: {},
}));

function ErrorState(props) {
  const { message } = props;
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <img src="images/Error@2x.png" className={classes.logo} />
      <Typography variant="h6">{message}</Typography>
    </div>
  );
}

ErrorState.propTypes = {
  message: PropTypes.string,
};

export default ErrorState;
