import React, { useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import ProfileMenu from "./ProfileMenu";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import IconButton from "@material-ui/core/IconButton";

const useStyles = makeStyles(() => ({
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  appBarLogo: {
    height: 50,
    margin: 8,
    marginRight: "auto",
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: "none",
  },
}));

function MainToolbar() {
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    console.log("nulling anchor el");
    setAnchorEl(null);
  };

  return (
    <Toolbar className={classes.toolbar} variant="dense">
      <img src="/images/rds-horz-white.png" className={classes.appBarLogo} />
      <IconButton color="inherit" onClick={handleClick}>
        <AccountCircleIcon />
      </IconButton>
      <ProfileMenu anchorEl={anchorEl} onClose={handleClose} />
    </Toolbar>
  );
}

MainToolbar.propTypes = {
  isDrawerOpen: PropTypes.bool,
  onDrawerOpen: PropTypes.func,
};

export default MainToolbar;
