import React from "react";
import { Switch, Route } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import SubscriptionTiers from "./SubscriptionTiers";
import CreateAccount from "./CreateAccount";
import VerifyEmail from "./VerifyEmail";
import SetupBilling from "./SetupBilling";
import PaymentComplete from "./PaymentComplete";
import AwaitingEmailVerification from "./AwaitingEmailVerification";

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    background: "linear-gradient(180deg, white, 60%, #004E89)",
    minHeight: "100vh",
    overflowY: "scroll",
  },
  topBar: {
    width: "100%",
    background: "white",
    borderBottom: "1px solid #EFEFEF",
  },
  topBarLogo: {
    height: 50,
    margin: 8,
    marginRight: "auto",
  },
  formBox: {
    width: "50%",
    minWidth: 300,
    maxWidth: 600,
    marginTop: "auto",
    marginBottom: "auto",
  },
}));

export default function SignUp() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.topBar}>
        <a href="https://runyondataservices.com">
          <img src="/images/rds-horz.png" className={classes.topBarLogo} />
        </a>
      </div>
      <Switch>
        <Route exact path="/sign-up">
          <SubscriptionTiers />
        </Route>
        <Route path="/sign-up/create-account">
          <CreateAccount />
        </Route>
        <Route path="/sign-up/waiting-for-verification">
          <AwaitingEmailVerification />
        </Route>
        <Route path="/sign-up/verify-email">
          <VerifyEmail />
        </Route>
        <Route path="/sign-up/setup-billing">
          <SetupBilling />
        </Route>
        <Route path="/sign-up/payment-complete">
          <PaymentComplete />
        </Route>
      </Switch>
    </div>
  );
}
